<template>
  <footer class="footer">
    <div class="footer-top" v-if="!isAboutPage">
      <div class="logo">
        <img src="../assets/logo-plus.png" />
      </div>
      <div class="company-info">
        <div>
          <el-icon size="0.18rem">
            <Location />
          </el-icon>
          Address: {{ $t('global.address') }}
        </div>
        <div>
          <el-icon size="0.18rem">
            <PhoneFilled />
          </el-icon>
          TEL: 400-8875-800
        </div>
        <div>
          <el-icon size="0.18rem">
            <Message />
          </el-icon>
          Email: syb@kingbarin.com
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container footer-bottom-container">
        © 2022 All Rights Reserved. <span class="notranslate">{{ $t('global.kingBarin') }}</span>有限公司版权所有 <el-link
          target="_blank" href="https://beian.miit.gov.cn/" style="color: #fff;">粤ICP备20018311号</el-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isAboutPage: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name == 'about') {
          this.isAboutPage = true
        }
      }
    }
  }
}
</script>

<script setup>
import { ref } from 'vue'
import { Location, PhoneFilled, Message } from '@element-plus/icons-vue'
// const onTop = () => {
//   document.documentElement.scrollTop = 0
// }
// const target_url = window.location.href.split('/#')[0]
const links = ref({
  about: {
    title: '关于金百瑞',
    items: ['团队介绍', '设计师团', '加入金百瑞', '媒体报道']
  },
  cooperation: {
    title: '商业合作',
    items: ['伙伴加盟', '集合采购', '媒体合作', '品牌合作']
  }
})

</script>
<style lang="scss" scoped>
.footer {
  box-sizing: border-box;
  background-size: cover;
  height: auto;

  &-container {
    width: 100%;
    color: #fff;
    background-color: rgba(#000, 0.9);

    &-hd {
      flex: 1;

      &-item {
        color: #ffffff;
        display: block;
        font-size: 16px;
        line-height: 34px;
      }
    }

    &-bd {
      width: 557px;
      position: relative;
      display: flex;
      justify-content: center;

      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 297px;
        background-color: #656565;
        position: absolute;
        z-index: 1;
        top: 80px;
        left: 0;
      }

      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 297px;
        background-color: #656565;
        position: absolute;
        z-index: 1;
        top: 80px;
        right: 0;
      }
    }

    &-ft {
      flex: 1;
      padding-left: 100px;
    }
  }

  &-links {
    margin: 0 50px;

    &-title {
      font-size: 17px;
      color: #9c9c9c;
      line-height: 24px;
      padding-bottom: 10px;
      border-bottom: 2px solid #979797;
      margin-bottom: 16px;
    }

    &-href {
      font-size: 17px;
      color: #ffffff;
      line-height: 34px;
    }
  }

  &-content {
    min-height: 454px;
    flex-wrap: wrap;
  }

  &-hd {
    width: 100%;
    display: flex;
  }

  &-hrefs {
    display: flex;
    margin-left: 22px;

    .href-item {
      margin-right: 40px;
      text-align: center;

      .text {
        margin-top: 10px;
      }
    }
  }

  &-href {
    display: block;
    width: 44px;
    height: 44px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 22px;

    &-tiktok {
      background-image: url('../assets/icon-tiktok.png');
      background-size: cover;

      &:hover {
        background-image: url('../assets/icon-tiktok-active.png');
        background-size: cover;
      }
    }

    &-bilibili {
      background-image: url('../assets/icon-bilibili.png');
      background-size: cover;

      &:hover {
        background-image: url('../assets/icon-bilibili-active.png');
        background-size: cover;
      }
    }

    &-weibo {
      background-image: url('../assets/icon-weibo.png');
      background-size: cover;

      &:hover {
        background-image: url('../assets/icon-weibo-active.png');
        background-size: cover;
      }
    }

    &-weixin {
      background-image: url('../assets/icon-weixin.png');
      background-size: cover;
      margin-left: 0;

      &:hover {
        background-image: url('../assets/icon-weixin-active.png');
        background-size: cover;
      }
    }
  }

  &-logo {
    width: 149px;
    height: 49px;
    display: block;
    font-size: 0;
    margin-bottom: 47px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-bottom {
    // min-height: 58px;
    background: #de0a0a;

    &-container {
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      line-height: 14px;
      padding: 14px;
    }
  }
}

.footer-weixin-code {
  background-color: #ffffff;

  img {
    width: 190px;
    height: auto;
  }
}

.footer-top {
  background-color: #F5F5F5;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 200px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .company-info {
    display: flex;
    align-items: center;
    margin-top: 20px;

    div {
      height: 20px;
      line-height: 20px;
      padding: 0 20px;
      display: flex;
      align-items: center;

      .el-icon {
        margin-right: 10px;
        color: #fff;
        background-color: #de0a0a;
        padding: 2px;
        border-radius: 25px;
      }
    }

    div:not(:last-child) {
      border-right: 3px solid #d09a7a;
    }
  }
}
</style>
