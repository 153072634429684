<template>
  <el-dropdown>
    <div class="dropdown-link">{{ $t('global.userCenter') }}</div>
    <template #dropdown>
      <el-dropdown-menu class="dropdown-menu">
        <el-dropdown-item>
          <router-link class="dropdown-menu-item" :to="{ name: 'order' }">{{ $t('dashboard.myOrder') }}</router-link>
        </el-dropdown-item>
        <el-dropdown-item v-if="mobile">
          <a class="dropdown-menu-item" href="javascript:;" @click="onLogout">{{ $t('global.logout') }}</a>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
const store = useStore()
const mobile = computed(() => {
  return store.getters?.account_info?.mobile
})
const onLogout = () => {
  store
    .dispatch('app/getLogout')
    .then((res) => {
      const { error, msg } = res
      if (error == 0) {
        ElMessage({
          message: msg || 'Success',
          grouping: true,
          type: 'success'
        })
      }
    })
    .catch(() => { })
}
</script>

<style lang="scss" scoped>
// .el-dropdown {
//   // width: 120px;
//   // margin-left: 40px;
// }

.dropdown {
  &-link {
    height: 30px;
    // width: 130px;
    background: url('../../assets/icon-user.png') no-repeat center left;
    background-size: 30px 30px;
    cursor: pointer;
    padding-left: 35px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1 !important;;
    align-items: center;
    display: flex;
  }

  &-menu {
    &-item {
      font-size: 16px;
      padding: 5px 10px;
      color: #000;
    }
  }
}
</style>
