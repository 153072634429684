<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { computed, ref } from 'vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
const language = ref('zh-cn')
const locale = computed(() => (language.value === 'zh-cn' ? zhCn : en))
</script>
<style lang="scss">
body {
  top:0 !important;
}

.skiptranslate {
  display: none;
  height: 0 !important;
  visibility: hidden
}

#google_translate_element {
  display: none;
}

@font-face {
  font-family: "title-font";
  src: url(~@/assets/fonts/Lora-VariableFont_wght.ttf) format("woff2");
  font-display: swap
}

</style>
