<template>
  <el-icon class="search-icon" @click="toSearch" v-if="!ifShowInput">
    <Search />
  </el-icon>
  <Transition name="slide-fade"  v-if="ifShowInput">
    <div class="search-container">
      <el-input v-model="kw" :placeholder="$t('order.search')" size="large" :after-icon="Search" clearable @change="search">
        <template #append>
          <el-button :icon="Search" />
        </template>
      </el-input>
      <!-- <el-button class="cancel-btn" @click="hideSearch">取消</el-button> -->
    </div>
  </Transition>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      ifShowInput: false
    }
  },
  methods: {
    toSearch() {
      this.ifShowInput = true
    },
    hideSearch() {
      this.ifShowInput = false
    }
  }
}
</script>

<script setup>
import { Search } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const kw = ref('')

const search = () => {
  router.push({ name: 'result', query: { kw: kw.value } })
}

</script>

<style lang="scss" scoped>
.el-input {
  // margin-right: 10px;

  :deep(.el-input__wrapper) {
    width: 180px;
    // height: 40px;
    // background: #eeeeee;
    // border: 1px solid #c9c9c9;
    font-size: 16px;
  }

  :deep(.el-input-group__append) {
    height: 40px;
    width: 60px;
    font-size: 26px;

    .el-button {
      padding: 0;
      line-height: 40px;
      color: #333;
    }

    .el-icon svg {
      transform: scale(.9);
    }
  }
}

.search-icon {
  cursor: pointer;
  font-size: 18px;
  transform: scale(1.2);
  // background: #e3e3e3;
  border-radius: 25px;
  padding: 2px;
}

.search-container {
  display: flex;
  align-items: center;

  .cancel-btn {
    height: 40px;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
