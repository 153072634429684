import request from '@/utils/request'

export function goodsSortList(params) {
  return request({
    url: '/goods-sort/list',
    method: 'get',
    params
  })
}

export function goodsSortListAll(params) {
  return request({
    url: '/goods-sort/all-list',
    method: 'get',
    params
  })
}

export function goodsList(params) {
  return request({
    url: '/goods/list',
    method: 'get',
    params
  })
}

export function goodsView(params) {
  return request({
    url: '/goods/view',
    method: 'get',
    params
  })
}

export function goodsHistoryList(params) {
  return request({
    url: '/goods/history',
    method: 'get',
    params
  })
}

/** 查看国家馆商品分类 */
export function sortCountryGoods(params) {
  return request({
    url: '/goods-sort/country-sort-list',
    method: 'get',
    params
  })
}

export function getDivisionList() {
  return request({
    url: '/goods/division-list',
    method: 'get',
  })
}
