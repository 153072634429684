export default {
  global: {
    kingBarin: 'Кинг Барин - Платформа для коллективных закупок',
    home: 'Главная',
    login: 'Войти',
    switchLanguage: 'Выберите язык',
    langZh: '中文',
    langEn: 'English',
    cart: 'Корзина',
    userCenter: 'Личный кабинет',
    welcome: 'Добро пожаловать во флагманский магазин GitaBox！',
    searchContent: 'Введите то, что вы хотите найти',
    search: 'Поиск',
    contactUs: 'Свяжитесь с нами',
    success: 'Успешно',
    fail: 'Сбой',
    logout: 'Выход',
    sendFail: 'Не удалось отправить. Повторите попытку позже!',
    clickCheck: 'Нажмите для просмотра',
    checkMore: 'Узнать больше',
    operate: 'Выполнить',
    delete: 'Удалить',
    deleteSuccess: 'Успешно удален',
    cancel: 'Отменить',
    save: 'Сохранить',
    cancelSuccess: 'Успешно отменен',
    editSuccess: 'Успешно изменено',
    submit: 'Отправить',
    none: 'нет',
    confirm: 'Подтвердить',
    uploadFail: 'Не удалось загрузить. Попробуйте еще раз',
    uploadSuccess: 'Успешно загружено',
    clear: 'Очистить',
    saveSuccess: 'Успешно сохранен',
    operation_manual: 'Руководство по эксплуатации',
    dashboard: 'Центр управления',
    notice: 'Новости',
    noticeDes: 'У вас новое сообщение, пожалуйста, проверьте',
    shopInfo: 'Управление корпоративной информацией',
    supplier: 'Управление поставщиками',
    oaApproval: 'Управление утверждениями OA',
    purchaseOrder: 'Управление заказами',
    productAnalysis: 'Управление анализом товаров',
    help: 'Часто задаваемые вопросы',
    aboutUs: 'О нас',
    enterpriseBenefits: 'Корпоративные льготы для сотрудников',
    travel: 'Деловая поездка',
    enterpriseProcurement: 'Централизованные закупки для предприятия',
    webTitle: 'Поставщик услуг по интегрированной цепочке поставок, использующий интеллектуальные и цифровые технологии в профессиональных сценариях<br/>',
    webDes: 'Предоставление клиентам решений в области цепочек поставок',
    address: 'Шэньчжэнь, район Лоху, здание Цзинцзи 100, Башня А, офис 4501-01A',
    customerService: 'Онлайн сервис',
    countryPavilions: 'Национальный павильон',
    enter: 'Войти',
    more: 'Еще',
    solution: 'Решение',
    categories: 'Все категории',
    commonLinks: 'Общие ссылки',
    rate: 'котировка иностранной валюты',
  },
  home: {
    senarioPro: 'Зона закупок по сценарию',
    cooperativeBrands: 'Кооперативные бренды',
    hotProducts: 'Ходовой товар',
    partner: 'Партнеры',
    commodityZone: 'Зона товаров',
    cooperation: 'Коммуникация и сотрудничество',
    demandResearch: 'Изучение запроса',
    signing: 'Подписание договора о сотрудничестве',
    solution: 'Решение',
    deployment: 'Развертывание и внедрение',
    optimization: 'Обзор и оптимизация',
  },
  login: {
    passwordLogin: 'Вход по паролю',
    mobileLogin: 'Вход по номеру телефона',
    phoneName: 'Введите номер телефона/имя пользователя/адрес электронной почты',
    inputName: 'Введите номер телефона/имя пользователя/адрес электронной почты',
    inputPossword: 'Введите пароль',
    selectArea: 'Выберите код города',
    inputMobile: 'Введите номер телефона',
    inputCaptcha: 'Введите код проверки',
    getCaptcha: 'Получить код проверки',
    registerAccount: 'Создать аккуант',
    forgetPassword: 'Забыли пароль',
    loginSuccess: 'Вход успешно совершен. Добро пожаловать.',
    residue: 'Осталось',
    loggingIn: 'Вход в систему',
    checkInfo: 'Проверите, заполнены ли все поля ввода ',
    loginDesc: 'Вы не вошли в систему. Пожалуйста, сначала войдите.',
    loginExpired: 'Ваша сессия истекла. Пожалуйста, войдите снова.',
    captcha: 'Код проверки',
    pleaseSelectEmail: 'Введите адрес электронной почты',
    password: 'Пароль',
    signUpSuccess: 'Регистрация прошла успешно. Пожалуйста, войдите в систему',
    logouting: 'Выход из системы',
    accountLoginTips: 'Если вы используете номер телефона для входа в систему, вам необходимо добавить пробел между кодом города и номером телефона, например：1 30*******'
  },
  dashboard: {
    userProfile: 'Профиль пользователя',
    orderCenter: 'Управление заказами',
    addressManagement: 'Управление адресами',
    accountInfo: 'Персональные данные',
    myCollect: 'Избранное',
    changePassword: 'Изменить пароль',
    myOrder: 'Мой заказ',
    refund: 'Возврат/послепродажное обслуживание',
    subNotice: 'Подписаться на уведомления',
    realName: 'Подтверждение личности',
    statement: 'Выписка',
    supplierList: 'Список поставщиков',
    productList: 'Список товаров',
    changeRecord: 'Запись о изменениях',
    activityRecords: 'Действия продавца',
    approvalProcess: 'Управление процессом утверждения',
    personnel: 'Управление персоналом',
    approvalForm: 'Форма утверждения закупок'
  },
  goods: {
    addCart: 'Добавить в корзину',
    collect: 'В избранное',
    cancleCollect: 'Удалить из избранного',
    collectSuccess: ' Успешно добавлен в избранное',
    cancelCollectSuccess: 'Успешно удалён из избранного.',
    productIntroduction: 'Описание',
    productParameters: 'Характеристики',
    buyNow: 'Купить',
    price: 'Цена',
    quantity: 'Количество',
    boxGgauge: 'Коробчатый калибр',
    delivery: 'Доставка',
    deliveryDesc: 'Продавец выбирает логистическую компанию, фактическая дата прибытия зависит от логистической компании.',
    minBuyNum: 'Минимальное количество покупки не может быть меньше 1 шт.',
    collectTime: 'Дата',
    cancelCollectNotice: 'Вы уверены, что хотите удалить из избранного?',
    sales: 'Объем продаж',
    latest: 'Новейший',
    totalNum: 'Всего {number} шт.',
    pcs: 'шт.',
    bag: 'пакет'
  },
  user: {
    avatar: 'Аватар',
    password: 'Пароль',
    confirmPassword: 'Подтвердить пароль',
    name: 'Имя пользователя',
    nickname: 'Ник',
    email: 'Почта',
    sex: 'Пол',
    male: 'М',
    female: 'Ж',
    age: 'Возраст',
    birthday: 'День рождения',
    description: 'Описание',
    account: 'Информация об учетной записи',
    mobile: 'Номер телефона',
    pleaseSelectName: 'Пожалуйста, введите Имя пользователя',
    pleaseSelectMobile: 'Пожалуйста, введите номер телефона',
    passwordAgin: 'Пожалуйста, введите пароль еще раз',
    setPassword: 'Пожалуйста, введите пароль',
    passwordCondition: 'Пароль должен содержать следующее от 8 до 20 символов буквы и цифры',
    noSamePassword: ' 2 введенных пароля не совпадают!',
    userExists: 'Имя пользователя уже существует',
    usePasswordEdit: 'Изменить с помощью пароля',
    oldPassword: 'Исходный пароль',
    inputOldPassword: 'Пожалуйста, введите исходный пароль',
    newPassword: 'Новый пароль',
    inputNewPassword: 'Пожалуйста, введите новый пароль',
    inputNewPasswordAgain: 'Пожалуйста, введите новый пароль еще раз',
    userMobileEdit: 'Изменить по номеру мобильного телефона',
    birthdayOne: 'День рождения можно указать только один раз, пожалуйста, заполняйте внимательно.Если вам нужно внести изменения, пожалуйста, обратитесь в службу поддержки клиентов, чтобы сделать это вручную.'
  },
  cart: {
    allSelect: 'Выбрать все',
    goodsInfo: 'Информация о продукте',
    price: 'Цена за единицу',
    amount: 'Сумма',
    invalidProduct: 'Недействительный товар',
    clearInvalidProduct: 'Очистить недействительные продукты',
    deleteSelect: 'Удалить выбранный товар',
    selectGoodsNum: 'Выбраны{number}изделий',
    total: 'Итого',
    toPay: 'Перейти к оплате',
    pleaseSelectGoods: 'Пожалуйста, выберите товар',
    confirmDelete: 'Вы уверены, что хотите удалить этот товар?',
    currencySame: 'Можно выбрать только одну и ту же валюту',
    pleaseAddCart: 'Ваша корзина пуста. Выберите товар.',
    goShopping: 'Перейти к покупке'
  },
  order: {
    comfirmOrder: 'Подтвердить заказ',
    enterOrder: 'Заполните и проверьте информацию о заказе',
    address: 'Адрес доставки',
    expand: 'Развернуть',
    retract: 'Свернуть',
    moreAddress: 'Больше адресов доставки',
    payType: 'Способ оплаты',
    onlinePay: 'Онлайн-платежи',
    goodsList: 'Список товаров',
    message: 'Сообщение покупателя',
    description: 'Если у вас есть особые требования к доставке, вы можете указать их в сообщении покупателя',
    totalAmount: 'Общая сумма',
    shippingFee: 'Стоимость доставки',
    shippingFeeDesc: 'Описание стоимости доставки',
    shippingFeeContent: {
      payment: 'Пожалуйста, терпеливо подождите после оформления заказа. Мы рассчитаем стоимость доставки для вас в течение 48 часов и своевременно уведомим вас. Пожалуйста, оплачивайте после подтверждения стоимости доставки.',
      conformation: 'Пожалуйста, терпеливо подождите после оформления заказа. Мы рассчитаем стоимость доставки для вас в течение 48 часов и своевременно уведомим вас.'
    },
    payTotal: 'Общая сумма к оплате',
    submitOrder: 'Отправить заказ',
    totalOrders: 'Всего{number}товаров',
    selectAddress: 'Пожалуйста, сначала выберите адрес',
    submitSuccess: 'Заказ выполнен успешно',
    orderNum: 'Номер заказа',
    currentStatus: 'Текущий статус заказа',
    shippingFeeCalculate: 'Рассчитать стоимость доставки',
    paySuccess: 'Платеж прошел успешно',
    shopShipment: 'Продавец отправляет товар',
    comfirmReceive: 'Подтвердить получение',
    transactionComplete: 'Транзакция завершена',
    shippingFeeNotice: {
      payment: '<h2>Уважаемый покупатель, здравствуйте</h2><p>стоимость доставки будет рассчитана<span> в течение 48 часов</span>，После завершения расчета<span></span>будет отправлено сообщение на номер телефона<span></span>，Пожалуйста, вернитесь к заказу для оплаты после получения SMS-сообщения<span></span><span></span></p>',
      conformation: '<h2>Уважаемый покупатель, здравствуйте</h2><p>стоимость доставки будет рассчитана<span>в течение 48 часов</span>，После завершения расчета<span></span>будет отправлено сообщение на номер телефона<span></span>，Пожалуйста, вернитесь к заказу для проверки прайслиста после получения SMS-сообщения <span></span>，<span>Выберите способ оплаты, убедившись в отсутствии ошибок</span></p>'
    },
    payment: 'Онлайн-платеж',
    cancelOrder: 'Отменить заказ',
    subtotal: 'Итог по товарам',
    applyRefund: 'Подать заявку на послепродажное обслуживание',
    viewRefund: 'Просмотр послепродажного обслуживания',
    logisticDetail: 'Детали доставки',
    sendTime: 'Дата отправки',
    logisticCompany: 'Логистическая компания',
    logisticNum: 'Номер отслеживания',
    logisticNone: 'Нет информации о доставке',
    logisticTacking: 'Отслеживание',
    goodsDetail: 'Подробная информация о товаре',
    status: 'Статус',
    consigneeInfo: 'Информация о получателе',
    consignee: 'Получатель',
    mobile: 'Контактный номер',
    remark: 'Примечание',
    ok: 'Ок',
    confirmCancelOrder: 'Подтверждаете, что хотите отменить заказ?',
    awaitPay: 'Заказ был успешно отправлен, пожалуйста, оплатите его скорее!Номер заказа: ：{number}',
    paySelect: 'Выбор способа оплаты',
    payAmount: 'Сумма платежа',
    paySuccessed: 'Поздравляем, платеж прошел успешно!',
    viewOrder: 'Просмотр заказа',
    returnHome: 'Вернуться на главную страницу',
    allOrder: 'Все заказы',
    awaitShippingFee: 'Стоимость доставки, подлежащая учету',
    awaitToPay: 'Подлежит к оплате',
    awaitSend: 'Подлежит к отправке',
    awaitReceive: 'Подлежит к получению',
    completed: 'Завершено',
    searchGoods: 'Пожалуйста, введите название товара или номер заказа',
    confirmDeleteOrder: 'Вы уверены, что хотите удалить заказ?',
    confirmReceiveOrder: 'Подтвердить получение',
    receiveSuccess: 'Успешно получено и подписано.',
    orderDetail: 'Детали заказа',
    specs: 'Спецификация',
    enterLogisticNum: 'Введите трек-номер',
    repurchase: 'Купить заново',
    expressCompany: 'Логистическая компания',
    expressNum: 'Трек-номер',
    selectExpressCom: 'Пожалуйста, выберите логистическую компанию!',
    selectExpressNum: 'Пожалуйста, введите трек-номер!',
    operateSuccess: 'Операция прошла успешно',
    search: 'поиск',
    paypalPay: 'Оплата через PayPal',
    wechatPay: 'Оплата через WeChat',
    wechatPayTip1: 'Пожалуйста, используйте WeChat для сканирования',
    wechatPayTip2: 'Отсканируйте QR-код для оплаты',
    productName: 'Название товара',
    productType: 'Тип товара',
    productPicture: 'Изображение товара',
    unit: 'единица измерения',
    quantity: 'количество',
    volume: 'объем',
    weight: 'вес',
    additionalServices: 'Дополнительные услуги',
    deliveryUpstairs: 'Если вам нужна доставка до двери, переупаковка товара, маркировка коробок и т.д.',
    pleaseProductName: 'Пожалуйста, введите название товара',
    pleaseProductType: 'Пожалуйста, укажите тип товара',
    pleaseQuantity: 'Пожалуйста, укажите количество',
    pleaseUnit: 'Пожалуйста, заполните единицу измерения',
    pleaseVolume: 'Пожалуйста, заполните объем',
    pleaseWeight: 'Пожалуйста, укажите вес',
    imageLimit: 'Можно загружать только файлы jpg/png, загружать до 5 изображений и не более 500 КБ',
    addMore: 'Добавить еще',
    attachment: 'Вложение',
    preview: 'Предпросмотр',
    download: 'Загрузить',
    dragFile: 'Перетащите файл сюда или нажмите Загрузить',
    fileLimit: 'Можно загружать только файлы jpg / png / word / excel / pdf ，размер не превышает {file_max_size} kb, загружать не более 3 вложений',
    merchantConsignee: 'Ф.И.О грузополучателя',
    merchantMobile: ' Номер телефона грузополучателя',
    merchantAddress: 'Адрес получателя',
    expressInfo: 'Введите трек-номер на доставку',
    changeExpressInfo: 'Измените трек-номер на доставку'
  },
  address: {
    default: 'По умолчанию',
    setDefault: 'Установить в качестве адреса по умолчанию',
    edit: 'Изменить',
    addAddress: 'Добавить новый адрес',
    firsName: 'Фамилия',
    lastName: 'Имя',
    mobile: 'Номер телефона',
    country: 'Страна/регион',
    division: 'Область/район/провинция/город',
    address: 'Подробный адрес',
    postalCode: 'Почтовый индекс',
    email: 'Почта'
  },
  refund: {
    refundType: 'Тип возврата',
    refundReason: 'Причина возврата',
    refundAmount: 'Сумма возврата',
    refundExplanation: 'Объяснение возврата средств',
    refundInstructions: 'Напишите объяснение возврата средств',
    submitApply: 'Подать заявку',
    selectRefundType: 'Пожалуйста, выберите тип возврата',
    selectRefundReason: 'Выберите причину возврата средств',
    saleProgress: 'Процесс послепродажного обслуживания',
    saleInfo: 'Информация о послепродажном обслуживании',
    refundNo: 'Номер возврата',
    saleStatus: 'Статус послепродажного обслуживания',
    applyReason: 'Причина подачи заявки',
    questionDesc: 'Описание проблемы',
    refundLogisticDetail: 'Информация о логистике возврата',
    merchantReview: 'Рассмотрение продавца',
    merchantRefund: 'Возврат средств продавцом',
    refundComplete: 'Возврат завершен',
    refundGoods: 'Товар возвращен.',
    merchantReceive: 'Получение товар продавцом',
    underReview: 'Ожидает рассмотрения',
    reviewSuccess: 'Рассмотрение не прошло',
    refundReview: 'Ожидает возврат',
    waitResive: 'Ожидание получения товара продавцом',
    waitRefund: 'Ожидание возврата средств',
    canceled: 'Отменён',
    refundComplete: 'Возврат средств завершен',
    refundOnly: 'Только возврат средств',
    returnGoods: 'Возврат средств и товара',
    repeatOrder: 'Повторная покупка, не нужна.',
    wrongOrder: 'Купил не то, купить заново',
    cheaper: 'Есть другие каналы с более дешевыми ценами',
    qualityProblem: 'Проблема качества',
    orderReason: 'Другие причины'
  },
  about: {
    aboutContent: {
      title1: 'Политическое руководство',
      content1: 'предпринимаются усилия по ускорению цифровой трансформации государственных предприятий в соответствии с важными указаниями Генерального Секретаря Си Цзиньпина о содействии комплексному развитию цифровой экономики и реального сектора экономики в целях выполнения решений и договоренностей Центрального Комитета КПК и Государственного Совета по поддержке цифровой экономики. Дальнейшая интеграция информационных технологий нового поколения и производственного сектора и укрепление новых преимуществ цифровой экономики стимулируют развитие государственных предприятий в направлении цифровых технологий, сетевой и интеллектуальной ориентации, повышении их конкурентоспособности, инновационного потенциала, способности контролировать, оказывать влияние и предотвращать риски, а также укреплении базовых производственных мощностей и модернизации производственных цепочек.',
      title2: 'King Barin Industry ',
      content2: 'это поставщик услуг по цепочке поставок с инновационным мышлением в области Интернет вещей, специализирующийся на управлении объектами недвижимости, и предоставляющий клиентам комплексные услуги по цепочке поставок с интеллектуальной интеграцией. Компания King Barin Industry всегда рассматривала принцип “изменения + инновации” как основную движущую силу долгосрочного развития компании и всегда оставалась на переднем крае развития Интернет вещей, внимательно следя за тенденциями модернизации потребительских и промышленных систем, помогая предприятиям переходить от традиционных моделей работы к интеллектуальным интегрированным услуги по цепочке поставок.',
      content3: 'Предоставляем клиентам решения для цифрового, интеллектуального, интегрированного сценария цепочки поставок и эксплуатации, помогая предприятиям реализовать цифровое интеллектуальное преобразование цепочки поставок. Наша цель - оптимизация операционной эффективности и повышение конкурентоспособности.'
    },    
    service: 'Сценарий обслуживания',
    qual: 'Квалификация предприятия',
    report: 'Отраслевые отчеты',
    policy: 'Политическое руководство',
    coreValue: 'Основная ценность',
    supplier: 'Управление цепочками',
    platformAdv: 'Преимущества платформы',
    contactUs: 'Свяжитесь с нами',
    kingbarinPublic: 'Публичный аккаунт King Barin',
    gitboxMini: 'Мини-программа Gita Box',
    platform: 'Персонализация платформы для закупок',
    purchasingCategories: 'Персонализация категории закупок',
    configService: 'Персонализация внутренних складских и дистрибьюторских услуг',
    newRetail: 'Персонализация новой розничной торговли онлайн и офлайн'
  },
  national: {
    'CNCountry': 'Павильон Китая',
    'CNBuy': 'Прямая поставка из Китая',
    'RUCountry': 'Павильон России',
    'RUBuy': 'Прямая поставка из России',
    'KPCountry': 'Павильон Северной кореи',
    'KPBuy': 'Прямая поставка из Северной Кореи',
    'AFCountry': 'Павильон Африки',
    'AFBuy': 'Прямая поставка из Африки',
    'BYCountry': 'Павильон Беларуси',
    'BYBuy': 'Прямая поставка из Беларуси',
    'NPCountry': 'Павильон Непала',
    'NPBuy': 'Прямая поставка из Непала',
    'THCountry': 'Павильон Таиланда',
    'THBuy': 'Прямая поставка из Таиланда',
    'MYCountry': 'Павильон Малайзии',
    'MYBuy': 'Прямая поставка из Малайзии',
    'roadAlliance': 'Альянс "Один пояс - один путь',
    'importGoods': 'Импортные товары',
    'importGoodsMore': 'Импортные товары с большим ассортиментом',
    'buyNow': 'Сразу купить',
    'briefIntroduce': 'Краткое описание',
    'advantage': 'Особенность продуктов',
    'introduce': 'Ознакомление ',
    'nationalFlag': 'Флаг',
    'nationalEmblem': 'Герб',
    'certificate': 'Доверенность'
  },
  solution : {
    headCompany: 'Головной консалтинг',
    headEnterprise: 'Головное предприятие',
    supplyManage: 'Управление поставками',
    supplyTitle: 'Вызовы управления цепочками поставок на предприятиях',
    supplyChallenge: '<li>Количество: Спрос колеблется и его трудно предсказать.</li><li>Модель: требуется персонализация и мощные возможности исследований и разработок в области настройки.</li><li>Время цикла: Цикл поставок становится короче, и организациям необходимо быстро реагировать.</li><li>Качество: Потребители предъявляют все более высокие требования к качеству.</li><li>Стоимость: На рынке царит жесткая ценовая конкуренция, и предприятия вынуждены сокращать расходы.</li>',
    supplyApplication: '<li>Интегрированное планирование цепочки поставок ISCP: интеллектуальное прогнозирование, быстрое реагирование, сокращение запасов.</li><li>Управление исследованиями и разработками PLM: централизованное управление требованиями заказчика; ускоренный выход на рынок; отслеживание всего цикла.</li><li>Управление качеством QMS: отслеживание полного цикла; улучшение возможностей предотвращения качеств.</li><li>Совместное управление клиентами CRM: управление приоритетами заказов, управление обязательствами по заказам и т. д.</li>',
    trainingDes: 'Исходя из потребностей цифрового проекта, учебные материалы настраиваются таким образом, чтобы направить бизнес-линии предприятия на проведение анализа болевых точек бизнеса с помощью сочетания теории и практики и совместной работы с членами предприятия по поиску решений, которые могут быть реализованы, чтобы способствовать внедрению решений и увеличить способность к реализации цифрового проекта на 30%.',
    supplyPlan: 'Планирование цепочки поставок',
    performance: 'Управление производительностью',
    workshopDes1: 'Семинар по совместному творчеству для 10-20 человек, основанный на конкретных бизнес-задачах компании. Фасилитатор семинара вдохновит бизнес-направления на нестандартное мышление с помощью методологии дизайн-мышления, сломает традиционный образ мышления при решении проблем и поможет организации найти инновационные решения.',
    workshopDes2: 'Основные этапы семинара, рассчитанные на 5 дней, позволяют повысить процент успеха цифровых проектов на 30 %.',
    worksFind: 'Обнаружение проблемы',
    worksSearch: 'Изучение коренных причин',
    worksIdea: 'Разработка идей',
    deepenIdeas: 'Углубление идей',
    inspect: 'Проверка решений',
    teamDes1: 'Наша команда - выходцы из McKinsey, Bain, Boston, Accenture, IBM, KPMG, Deloitte, Ernst & Young, PricewaterhouseCoopers и других всемирно известных консалтинговых организаций, а также компаний Emerson, Oracle, Microsoft, Lenovo, Huawei, Trina Solar и других, входящих в список Fortune 500, и все наши сотрудники имеют более чем 15-летний опыт работы в реальном мире.',
    teamDes2: 'Мы способны предоставлять комплексные интегрированные решения в области цепочек поставок, включая: интегрированное планирование цепочек поставок, цифровые закупки, интеллектуальное производство, интеллектуальную логистику и другие цифровые проекты, планирование и внедрение на высшем уровне, мы обладаем богатыми возможностями по адаптации к требованиям отрасли, цифровые технологии помогают компаниям сократить расходы и повысить эффективность, улучшить качество и сократить выбросы углерода.',
    scanCode: 'Отсканируйте код, чтобы получить последние новости',
    supplyTitle: 'Применение каждого подмодуля интегрированного решения для цепочки поставок',
    trainingTitle: 'Содержание обучения охватывает 12 модулей.',
    advantage1: '70% нашей команды представляют ведущие консалтинговые организации, такие как McKinsey, Boston, Bain, PwC, Accenture, IBM, KPMG и т. д., и имеют более 15 лет опыта проектирования высшего уровня в крупномасштабных цифровых проектах, включая стратегические. интерпретация и диагностика болевых точек, анализ пробелов, разработка решений, сортировка процессов, выявление возможностей, планирование пути, выбор системы, оценка рисков и т. д.'
  }
}
