export default {
  global: {
    kingBarin: '讯金百瑞集采平台',
    home: '首页',
    login: '登录',
    switchLanguage: '切换语言',
    langZh: '简体中文',
    langEn: 'English',
    cart: '购物车',
    userCenter: '个人中心',
    welcome: '欢迎来到金百瑞集采！',
    searchContent: '请输入要搜索的内容',
    search: '搜一搜',
    contactUs: '联系我们',
    success: '成功',
    fail: '失败',
    logout: '退出',
    sendFail: '发送失败，请稍后重试!',
    clickCheck: '点击查看',
    checkMore: '查看更多',
    operate: '操作',
    delete: '删除',
    deleteSuccess: '删除成功',
    cancel: '取 消',
    save: '保存',
    cancelSuccess: '取消成功',
    editSuccess: '修改成功',
    submit: '提交',
    none: '无',
    confirm: '确 定',
    uploadFail: '上传失败，请重新上传',
    uploadSuccess: '上传成功',
    clear: '清空',
    saveSuccess: '保存成功',
    operation_manual: '操作手册',
    dashboard: '管理中心',
    notice: '消息',
    noticeDes: '您有新的消息，请及时查看',
    shopInfo: '企业信息管理',
    supplier: '供应商管理',
    oaApproval: 'OA审批管理',
    purchaseOrder: '采购订单管理',
    productAnalysis: '商品分析管理',
    help: '常见问题',
    aboutUs: '关于我们',
    enterpriseBenefits: '企业员工福利',
    travel: '差旅出行',
    enterpriseProcurement: '企业集采入口',
    webTitle: '专业场景数智化集成<br/>供应链服务商',
    webDes: '为客户提供全链条供应链解决方案',
    address: '深圳市罗湖区桂园街道老围社区深南东路5016号蔡屋围京基一百大厦A座4501-01A',
    customerService: '在线咨询',
    countryPavilions: '国家馆',
    enter: '进入',
    more: '更多',
    solution: '解决方案',
    categories: '全部分类',
    commonLinks: '常用链接',
    rate: '外汇牌价',
  },
  home: {
    senarioPro: '场景采购专区',
    cooperativeBrands: '合作品牌',
    hotProducts: '热销商品',
    partner: '合作伙伴',
    commodityZone: '商品专区',
    cooperation: '合作沟通',
    demandResearch: '需求调研',
    signing: '签约合作',
    solution: '解决方案',
    deployment: '部署实施',
    optimization: '复盘优化'
  },
  login: {
    passwordLogin: '密码登录',
    mobileLogin: '手机号登录',
    phoneName: '请输入手机号或用户名',
    inputName: '请输入手机号或用户名',
    inputPossword: '请输入密码',
    selectArea: '请选择区号',
    inputMobile: '请输入手机号',
    inputCaptcha: '请输入验证码',
    getCaptcha: '获取验证码',
    registerAccount: '注册账号',
    forgetPassword: '忘记密码',
    loginSuccess: '登录成功，欢迎您',
    residue: '剩余',
    loggingIn: '登录中...',
    checkInfo: '请检查信息是否填写完整',
    loginDesc: '检测到您还未登录，请先登录',
    loginExpired: '登录状态已失效，请重新登录',
    captcha: '验证码',
    pleaseSelectEmail: '请输入邮箱',
    password: '密码',
    signUpSuccess: '注册成功，请登录',
    logouting: '退出登录中',
    accountLoginTips: '提示：若使用手机号登录，区号和手机号间需要加个空格，如：1 30*******'
  },
  dashboard: {
    userProfile: '用户信息',
    orderCenter: '订单中心',
    addressManagement: '地址管理',
    accountInfo: '个人信息',
    myCollect: '我的收藏',
    changePassword: '修改密码',
    myOrder: '我的订单',
    refund: '退款/售后',
    subNotice: '订阅通知',
    realName: '实名信息',
    statement: '对账单查询',
    supplierList: '供应商列表',
    productList: '商品列表',
    changeRecord: '变更记录',
    activityRecords: '供应商活动记录',
    approvalProcess: '审批流程管理',
    personnel: '人员管理',
    approvalForm: '采购审批单'
  },
  goods: {
    addCart: '加入购物车',
    collect: '收藏',
    cancleCollect: '取消收藏',
    collectSuccess: '收藏成功',
    cancelCollectSuccess: '取消收藏成功',
    productIntroduction: '产品介绍',
    productParameters: '商品参数',
    buyNow: '立即购买',
    price: '售价',
    quantity: '数量',
    boxGgauge: '箱规',
    delivery: '配送',
    deliveryDesc: '由商家选择发货物流，实际到货日期以物流公司时间为准',
    minBuyNum: '最小购买数量不能小于1',
    collectTime: '收藏时间',
    cancelCollectNotice: '确定取消收藏吗？',
    sales: '销量',
    latest: '最新',
    totalNum: '共 {number} 个',
    pcs: '件',
    bag: '袋'
  },
  user: {
    avatar: '头像',
    password: '密码',
    confirmPassword: '确认密码',
    name: '用户名',
    nickname: '昵称',
    email: '邮箱',
    sex: '性别',
    male: '男',
    female: '女',
    age: '年龄',
    birthday: '生日',
    description: '描述',
    account: '账户信息',
    mobile: '手机号',
    pleaseSelectName: '请输入用户名',
    pleaseSelectMobile: '请输入手机号',
    passwordAgin: '请再次输入密码',
    setPassword: '请输入密码',
    passwordCondition: '密码为8-20位，且同时包含字母、数字',
    noSamePassword: '两次输入密码不一致!',
    userExists: '用户名已存在',
    usePasswordEdit: '通过密码修改',
    oldPassword: '原密码',
    inputOldPassword: '请输入原密码',
    newPassword: '新密码',
    inputNewPassword: '请输入新密码',
    inputNewPasswordAgain: '请再次输入新密码',
    userMobileEdit: '通过手机号修改',
    birthdayOne: '生日只可设置一次，请认真填写。如需修改，请联系客服人工处理'
  },
  cart: {
    allSelect: '全选',
    goodsInfo: '商品信息',
    price: '单价',
    amount: '金额',
    invalidProduct: '失效商品',
    clearInvalidProduct: '清理失效商品',
    deleteSelect: '删除选中的商品',
    selectGoodsNum: '已选{number}件',
    total: '合计',
    toPay: '去结算',
    pleaseSelectGoods: '请选择商品',
    confirmDelete: '确定删除该商品?',
    currencySame: '只能选择相同货币商品',
    pleaseAddCart: '购物车空空如也，快来挑好货呗',
    goShopping: '去购物'
  },
  order: {
    comfirmOrder: '确认订单',
    enterOrder: '填写并核对订单信息',
    address: '收货地址',
    expand: '展开',
    retract: '收起',
    moreAddress: '更多收货地址',
    payType: '支付方式',
    onlinePay: '在线支付',
    goodsList: '商品列表',
    message: '买家留言',
    description: '如有特殊交货要求，可在买家留言中注明',
    totalAmount: '总计金额',
    shippingFee: '运费',
    shippingFeeDesc: '运费说明',
    shippingFeeContent: {
      payment: '请在下单后耐心等待，我们会在48小时内为您计算运费并及时通知。在确认运费后，请再确认是否进行支付。',
      conformation: '请在下单后耐心等待，我们会在48小时内为您计算运费并及时通知。'
    },
    payTotal: '应付总额',
    submitOrder: '提交订单',
    totalOrders: '共{number}件商品',
    selectAddress: '请先选择地址',
    submitSuccess: '下单成功',
    orderNum: '订单编号',
    currentStatus: '当前订单状态',
    shippingFeeCalculate: '核算运费',
    paySuccess: '付款成功',
    shopShipment: '商家发货',
    comfirmReceive: '确认收货',
    transactionComplete: '交易完成',
    shippingFeeNotice: '<h2>尊敬的客户，您好</h2><p>您的运费将在<span>48小时内</span>核算给您，核算完成后会<span>以短信形式</span>发送到您注册时<span>预留的手机号</span>，请收到短信后返回该<span>订单</span>进行<span>支付</span></p>',
    payment: '支付',
    cancelOrder: '取消订单',
    subtotal: '商品小计',
    applyRefund: '申请售后',
    viewRefund: '查看售后',
    logisticDetail: '物流详情',
    sendTime: '发货时间',
    logisticCompany: '物流公司',
    logisticNum: '物流单号',
    logisticNone: '暂无物流信息',
    logisticTacking: '物流跟踪',
    goodsDetail: '商品详情',
    status: '状态',
    consigneeInfo: '收货人信息',
    consignee: '收货人',
    mobile: '联系电话',
    remark: '备注',
    ok: '我知道了',
    confirmCancelOrder: '确认要取消订单吗?',
    awaitPay: '订单提交成功，请尽快付款！订单号：{number}',
    paySelect: '支付方式选择',
    payAmount: '支付金额',
    paySuccessed: '恭喜您，支付成功！',
    viewOrder: '查看订单',
    returnHome: '返回首页',
    allOrder: '全部订单',
    awaitShippingFee: '待核算运费',
    awaitToPay: '待付款',
    awaitSend: '待发货',
    awaitReceive: '待收货',
    completed: '已完成',
    searchGoods: '请输入商品名称或订单号',
    confirmDeleteOrder: '确认要删除订单吗？',
    confirmReceiveOrder: '是否确认收货？',
    receiveSuccess: '签收成功',
    orderDetail: '订单详情',
    specs: '规格',
    enterLogisticNum: '填写物流单号',
    repurchase: '重新购买',
    expressCompany: '快递公司',
    expressNum: '快递单号',
    selectExpressCom: '请选择物流公司!',
    selectExpressNum: '请填写物流单号!',
    operateSuccess: '操作成功',
    search: '搜索',
    paypalPay: 'PayPal支付',
    wechatPay: '微信支付',
    wechatPayTip1: '请使用微信扫一扫',
    wechatPayTip2: '扫描二维码支付',
    productName: '物品名称',
    productType: '物品类型',
    productPicture: '物品图片',
    unit: '单位',
    quantity: '数量',
    volume: '体积',
    weight: '重量',
    additionalServices: '额外服务',
    deliveryUpstairs: '如需送货上楼、商品重新包装、贴箱唛等',
    pleaseProductName: '请填写物品名称',
    pleaseProductType: '请填写物品类型',
    pleaseQuantity: '请填写数量',
    pleaseUnit: '请填写单位',
    pleaseVolume: '请填写体积',
    pleaseWeight: '请填写重量',
    imageLimit: '只能上传jpg/png文件，最多上传5张图片，且不超过500kb',
    addMore: '添加更多',
    attachment: '附件',
    preview: '预览',
    download: '下载',
    dragFile: '将文件拖到此处，或点击上传',
    fileLimit: '只能上传 jpg / png / word / excel / pdf 文件，且大小不超过 {file_max_size} kb, 最多上传3个附件',
    merchantConsignee: '商家收货人姓名',
    merchantMobile: ' 商家收货人手机号',
    merchantAddress: '商家收货地址',
    expressInfo: '填写发货物流单号',
    changeExpressInfo: '修改发货物流单号'
  },
  address: {
    default: '默认',
    setDefault: '设为默认地址',
    edit: '修改',
    addAddress: '添加新地址',
    firsName: '姓',
    lastName: '名',
    mobile: '手机号',
    country: '国家/地区',
    division: '州/区/省/市',
    address: '地址',
    postalCode: '邮编',
    email: '邮箱'
  },
  refund: {
    refundType: '退款类型',
    refundReason: '退款原因',
    refundAmount: '退款金额',
    refundExplanation: '退款说明',
    refundInstructions: '写出您的退款说明',
    submitApply: '提交申请',
    selectRefundType: '请选择退款类型',
    selectRefundReason: '请选择退款原因',
    saleProgress: '售后进度',
    saleInfo: '售后信息',
    refundNo: '退款编号',
    saleStatus: '售后状态',
    applyReason: '申请原因',
    questionDesc: '问题描述',
    refundLogisticDetail: '退货物流信息',
    merchantReview: '商家审核',
    merchantRefund: '商家退款',
    refundComplete: '退款完成',
    refundGoods: '退回商品',
    merchantReceive: '商家收货',
    underReview: '待审核',
    reviewSuccess: '审核不通过',
    refundReview: '待退货',
    waitResive: '待商家收货',
    waitRefund: '待退款',
    canceled: '已取消',
    refundComplete: '退款完成',
    refundOnly: '仅退款',
    returnGoods: '退货退款',
    repeatOrder: '重复购买，不需要',
    wrongOrder: '买错了，重新购买',
    cheaper: '有其它价格更便宜的渠道',
    qualityProblem: '质量问题',
    orderReason: '其他原因'
  },
  about: {
    aboutContent: {
      title1: '政策导向',
      content1: '习近平总书记关于推动数字经济和实体经济融合发展的重要指示精神，落实党中央、国务院关于推动新一代信息技术与制造业深度融合，打造数字经济新优势等决策部署，促进国有企业数字化、网络化、智能化发展，增强竞争力、创新力、控制力、影响力、抗风险能力，提升产业其础能力和产业链现代化水平，现就加快推进国有企业数字化转型工作。',
      title2: '金百瑞实业',
      content2: '是一家具备物联网创新思维，专注于不动产运营场景，为客户提供数智化集成供应链的服务商，金百瑞一直把“变革＋创新”作为公司长期发展的第一驱动力，并始终站在物联网数字化趋势的高度，紧随消费升级＋产业化升级趋势，帮助企业从传统运营模式向数智化集成供应链服务转型。',
      content3: '为客户提供数智化集成供应链解决方案和场景运营服务，助力企业实现数智化供应链转型，优化企业运营效率，提升企业行业竞争力。'
    },
    service: '服务场景',
    qual: '企业资质',
    report: '行业报道',
    policy: '政策指引',
    coreValue: '核心价值',
    supplier: '供应链管理',
    platformAdv: '平台优势',
    contactUs: '联系我们',
    kingbarinPublic: '金百瑞公众号',
    gitboxMini: '几个盒子小程序',
    platform: '采购平台定制化',
    purchasingCategories: '采购品类集采定制化',
    configService: '后端仓配服务定制化',
    newRetail: '线上线下新零售定制化'
  },
  national: {
    'CNCountry': '中国馆',
    'CNBuy': '中国直购',
    'RUCountry': '俄罗斯馆',
    'RUBuy': '俄罗斯直购',
    'KPCountry': '朝鲜馆',
    'KPBuy': '朝鲜直购',
    'AFCountry': '非洲馆',
    'AFBuy': '非洲直购',
    'BYCountry': '白俄罗斯馆',
    'BYBuy': '白俄罗斯直购',
    'NPCountry': '尼泊尔馆',
    'NPBuy': '尼泊尔直购',
    'THCountry': '泰国馆',
    'THBuy': '泰国直购',
    'MYCountry': '马来西亚馆',
    'MYBuy': '马来西亚直购',
    'roadAlliance': '一带一路联盟',
    'importGoods': '进口商品',
    'importGoodsMore': '进口商品，种类多多',
    'buyNow': '立即抢购',
    'briefIntroduce': '简介',
    'advantage': '产品特色',
    'introduce': '介绍',
    'nationalFlag': '国旗',
    'nationalEmblem': '国徽',
    'certificate': '授权书'
  },
  solution : {
    headCompany: '头部咨询公司',
    headEnterprise: '头部实体企业',
    supplyManage: '供应管理',
    supplyTitle: '企业供应链管理面临的挑战',
    supplyChallenge: '<li>数量：需求波动大，难以预测。</li><li>型号：需求个性化，需要很强的定制研发能力。</li><li>周期：交付周期越来越短，企业需要快速响应。</li><li>质量：消费者对质量的要求越来越高。</li><li>成本：市场价格竞争激烈，企业降本压力大。</li>',
    supplyApplication: '<li>集成供应链计划ISCP:  智能预测；快速响应, 降低存货。</li><li>研发管理PLM：客户需求集中管理；快速上市；全生命周期跟踪。</li><li>质量管理QMS:   全生命周期追溯；提升质量预防能力。</li><li>客户协同管理CRM:   订单优先级管理；订单承诺管理等。</li>',
    trainingDes: '基于数字化项目的需求定制培训材料，通过理论与实践相结合的方式引导企业各业务线对业务痛点做根因分析并与企业的成员共同找出可落地的解决措施，推动企业将解决方案落地实施，数字化项目的落地能力提升30%。',
    supplyPlan: '供应链计划',
    performance: '绩效管理',
    workshopDes1: '基于企业特定的业务目标组建10-20人左右的共创式工作坊。工作坊的主持人将通过设计思维的方法激发企业各业务线进行突破性地思维，打破传统的解决问题的思路，协助企业找到创新的解决方案。',
    workshopDes2: '工作坊的5大核心步骤:数字化项目的成功率提升30%',
    worksFind: '发现问题',
    worksSearch: '探索根因',
    worksIdea: '提出想法', 
    deepenIdeas: '深化想法', 
    inspect: '方案检验',
    teamDes1: '我们的团队源自麦肯锡，贝恩，波士顿，埃森哲，IBM，毕马威，德勤，安永，普华永道等全球知名咨询机构及艾默生，甲骨文，微软，联想，华为，天合光能等头部500强企业，所有的成员均具备15年以上的实战经验。',
    teamDes2: '我们具备端到端集成供应链整体解决方案的交付能力，包括：集成供应链计划，数字化采购，智能制造，智慧物流等数字化项目的顶层规划及落地实施，我们拥有丰富的行业定制能力，用数字化技术帮助企业降本增效提质减碳。',
    scanCode: '扫码关注获取最新资讯',
    supplyTitle: '集成供应链解决方案各子模块的应用',
    trainingTitle: '培训内容覆盖12个模块',
    advantage1: '我们的团队有70%源自麦肯锡.波士顿,贝恩.普华永道,埃森哲,IBM,毕马威等头部咨询机构,并具备15年以上大型的数字化项目顶层设计经验,包括战略解读、痛点诊断、差距分析、方案设计、流程梳理、能力识别、路径规划、系统选型、风险评估等。'
  }
}
