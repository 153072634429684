// import VueI18n from 'vue-i18n'
import { getCookies} from '@/utils/auth.js'
// import elementEnLocale from 'element/lib/locale/lang/en' // element-plus lang
// import elementZhLocale from 'element/lib/locale/lang/zh-CN'// element-plus lang
import elementZhLocale from 'element-plus/dist/locale/zh-cn.mjs'
import elementEnLocale from 'element-plus/dist/locale/en.mjs'
import enLocale from './en'
import zhLocale from './zh'
import ruLocale from './ru'

import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  ru: {
    ...ruLocale,
    ...elementEnLocale
  }
}

export function getLanguage($isI18n = false) {
  const chooseLanguage = getCookies('currentLanguage')

  if ($isI18n) {
    if ((chooseLanguage == 'zh' || chooseLanguage == 'en' || chooseLanguage == 'ru')) {
      return chooseLanguage
    }
  } else if (chooseLanguage) {
    return chooseLanguage
  }

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'zh'
}

const i18n = createI18n({
  legacy: false,
  locale: getLanguage(true), // 设置默认语言
  messages
})

export function t(key, args) {
  if (!i18n) return key
  return i18n.global.t(key, args)
}

export default i18n

// console.log({
//   messages,
//   locale: getLanguage(),
// });

// const i18n = new VueI18n({
//   locale: getLanguage(), // 默认语言
//   messages
// });

// export default i18n;
