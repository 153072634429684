<template>
  <div class="categorize-content">
      <el-popover  trigger="hover"
        :offset="0"
        :show-arrow="false"
        :visible="popoverVisible"
        :width="'2.2rem'"
        placement="bottom-start"
        popper-class="xg-menu-popover popover-menu"
        >
        <template #reference>
        <div class="xg-menu-btn" @mouseover.passive="popoverShow" @mouseleave="popoverHide">
          <i class="icon"><svg t="1712480032576" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6238"><path d="M332.3904 813.4656h545.1776c17.8176 0 31.3344-13.312 31.3344-31.3344s-13.312-31.3344-31.3344-31.3344H332.3904c-17.8176 0-31.3344 13.312-31.3344 31.3344 0 13.312 13.5168 31.3344 31.3344 31.3344z m0-268.288h545.1776c22.3232 0 35.84-13.312 35.84-31.3344s-13.312-31.3344-31.3344-31.3344H332.3904c-17.8176-4.5056-31.3344 9.0112-31.3344 31.3344 0 18.0224 13.5168 31.3344 31.3344 31.3344z m0-263.5776h545.1776c22.3232 0 35.84-17.8176 35.84-35.84 0-17.8176-13.312-31.3344-31.3344-31.3344H332.3904c-17.8176 0-31.3344 13.312-31.3344 31.3344 0 17.8176 13.5168 35.84 31.3344 35.84zM158.1056 826.7776c26.8288 0 49.152-22.3232 49.152-49.152s-22.3232-49.152-49.152-49.152-49.152 22.3232-49.152 49.152 22.3232 49.152 49.152 49.152z m49.152-312.9344c0-26.8288-22.3232-49.152-49.152-49.152-26.8288-4.5056-49.152 17.8176-49.152 49.152 0 26.8288 22.3232 49.152 49.152 49.152s49.152-22.3232 49.152-49.152zM158.1056 294.912c26.8288 0 49.152-22.3232 49.152-49.152s-22.3232-49.152-49.152-49.152-49.152 22.3232-49.152 49.152 22.3232 49.152 49.152 49.152z m0 0" p-id="6239" fill="#ffffff"/></svg></i>
          <div class="all-sort">{{ $t('global.categories') }}</div>
          <el-icon>
            <ArrowUp  v-if="popoverVisible" />
            <ArrowDown v-else />
          </el-icon>
        </div>
        </template>
        <div class="menu-horizontal" ref="categorizeRef" @mouseleave="popoverHide">
          <ul class="menu" ref="navBox">
            <li class="menu-item" v-for="(item, i) in sortListAll" :key="i" @click="handleCateClick(item)"  @mouseover.passive="show(i)">
              <div class="menu__title">
                <img class="icon-img" :src="item.icon" alt="" />
                <div class="sort-title line-ellipsis" :title="item.title">{{ item.title }}</div>
                <el-icon class="icon-arrow">
                  <ArrowRight />
                </el-icon>
              </div>
            </li>
          </ul>
        </div>
      </el-popover>
      <!-- :hide-after="500" -->
      <el-popover
        ref="popoverRef"
        :virtual-ref="categorizeRef"
        trigger="hover"
        :offset="0"
        :show-arrow="false"
        :width="'10.8rem'"
        :visible="subPopoverVisible"
        placement="right-start"
        virtual-triggering
        popper-class="xg-menu-popover"
        @hide="resetPopover"
      >
        <div v-if="subSort.items && subSort.items.length > 0" class="sub-category" @mouseleave="popoverHide" @mouseover.passive="popoverShow">
            <div class="global-nav-item fl">
              <div class="cate-list">
                <div class="cate-item" v-for="(child1, j) in subSort.items" :key="j">
                  <div class="cate-img">
                    <img :src="child1.img" alt="" />
                  </div>
                  <dl>
                    <dt>
                      <router-link :to="'sort?alias=' + child1.alias + '&id=' + subSort.id" @click="hide">{{ child1.title }}</router-link>
                    </dt>
                    <dd>
                      <a :href="'#/sort?alias=' + child2.alias + '&id=' + subSort.id" v-for="(child2, k) in child1.items" :key="k" @click="hide">
                        {{ child2.title }}
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
        </div>
      </el-popover>
  </div>

</template>

<script setup>
import { goodsSortListAll} from '@/api/goods'
import { ref, onMounted, unref} from 'vue'
import { useRouter } from 'vue-router'
import { ArrowDown, ArrowUp, ArrowRight} from '@element-plus/icons-vue'

// import { ClickOutside as vClickOutside } from 'element-plus'
const categorizeRef = ref()
const popoverRef = ref()
// const onClickOutside = () => {
//   unref(popoverRef).popperRef?.delayHide?.()
// }
const router = useRouter()
const sortListAll = ref([])
const middleIdx = ref('')
const popoverVisible = ref(false)
const subPopoverVisible = ref(null)
const subSort = ref([])

onMounted(() => {
  initGoodsSort()
})

const customPopperStyle = ref({})
const initGoodsSort = () => {
  goodsSortListAll({ parent_id: 1 }).then((res) => {
    const { error, data } = res
    if (error == 0) {
      const { items } = data || {}
      sortListAll.value = items
      // console.log('sortListAll',sortListAll.value[0])
      if (items[0] && items[0]['items'] && items[0]['items'].length) {
        subSort.value = items[0]
      }
    }
  })
}

const popoverShow = () => {
  // console.log('popoverVisible.value', popoverVisible.value)
  popoverVisible.value = true
}

const popoverHide = () => {
  // console.log('popoverVisible.value', popoverVisible.value)
  popoverVisible.value = false
  subPopoverVisible.value = null
}
const show = (index) => {
  if (!sortListAll.value[index]) return
  popoverVisible.value = true
  subPopoverVisible.value = true
  // sortListAll.value[index].selected = true
  subSort.value = sortListAll.value[index]
}
const hide = () => {
  popoverVisible.value = false
  subPopoverVisible.value = false
}

const resetPopover = () => {
  subPopoverVisible.value = null
}

const handleCateClick = (item) => {
  popoverHide()
  router.push({
    name: 'sort',
    query: {
      alias: item.alias,
      id: item.id
    }
  })
}

</script>

<style scoped>
.xg-menu-btn {
  color: #ffffff;
  position: relative;
  height: 50px;
  width: 220px;
  line-height: 1;
  font-size: 18px;
  padding: 0 10px;
  background-color: var(--el-color-primary);
  /* border: none; */
  display: flex;
  /* text-align: center; */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
}

.global-nav-item {
  min-width: 140px;
  padding-right: 10px;
}

.global-nav-item a {
  color: #333;
}

.global-nav-item dl {
  /* padding-top: 15px; */
  /* float: left; */
  height: auto;
  overflow: hidden;
  /* width: 550px; */
}

.global-nav-item dd a {
    color: #000;
    margin-right: 15px;
    display: inline-block;
    font-family: 'Microsoft Yahei', tahoma, arial, 'Hiragino Sans GB';
    text-decoration: none;
}

.global-nav-item dt {
  height: 35px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  line-height: 35px;
  font-size: 14px;
  font-weight: 700;
  font-family: Source Han Sans;
}

.global-nav-item dt a {
  display: block;
  max-width: 115px;
  height: 35px;
  line-height: 35px;
  overflow: hidden;
}

.global-nav-item dd {
  /* height: 25px;
  line-height: 25px; */
  /* float: left; */
  width: 880px;
  font: 12px / 1.5 arial, tahoma, \5b8b\4f53;
  line-height: 20px;
  overflow: hidden;
  border-bottom: 1px solid #edebeb;
  margin-top: 8px;
  padding-bottom: 16px;
  font-family: Source Han Sans;
}

.categorize-content {
  display: flex;
  width: 220px;
  align-items: end;
  margin-right: 40px;
}

.menu-horizontal {
  display: flex;
  flex-wrap: nowrap;
  /* border-bottom: solid 0.01rem #909399;; */
  border-right: none;
}

.menu {
  /* border-right: solid 0.01rem #dcdfe6; */
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  /* background-color: #ffffff; */
  box-sizing: border-box;
  min-width: 219px;
}

.menu .menu-item {
    /* background-color: #ffffff; */
    /* display: flex; */
    align-items: center;
    height: 0.36rem;
    padding: 0 0.2rem;
    color: #303133;
}

.sub-menu .menu-item {
    height: calc(0.56rem - 0.06rem);
    line-height: calc( 0.56rem - 0.06rem);
    min-width: 2rem;
}
/* //#f1f1f1; */
.menu-item:hover {
  background-color: #f1f1f1;
}

.menu-item .menu__title{
    display: flex;
    align-items: center;
    height: 0.36rem;
    line-height: 0.36rem;
    font-size: 0.14rem;
    color: #303133;
    /* padding: 0 0.2rem; */
    list-style: none;
    cursor: pointer;
    position: relative;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    box-sizing: border-box;
    white-space: nowrap;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

.icon-img {
  vertical-align: middle;
  margin-right: 0.1rem;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 0.18rem;
}
/* .menu .icon {
    flex-shrink: 0;
} */
.menu__title i {
    color: inherit;
}

.icon {
  width: 20px; 
  height: 20px;
  --color: inherit;
  /* height: 1em;
  width: 1em; */
  /* line-height: 1em; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  fill: currentColor;
  color: inherit;
  font-size: inherit;
  /* padding-top: 5px; */
  /* padding-right: 10px; */
}

.icon-arrow {
  height: 1em;
  width: 1em;
  line-height: 1em;
  position: absolute;
  top: 50%;
  right: 0px;
  margin-top: -0.07rem;
  transform: none;
  transition: 0.3s;
  font-size: 0.12rem;
  margin-right: 0;
}

.sub-category {
  z-index: 1000;
  min-height: 476px;
  overflow: hidden;
  background: #f3f3f3;
  font-size: 12px;
}

/* #f3f3f3; */
.index-sort-detail {
    /* position: absolute; */
    /* left: 200px; */
    min-height: 476px;
    /* width: 0; */
    overflow: hidden;
    background: #F3F1F2;
    z-index: 7676;
    _height: 451px;
}

.cate-list {
    width: 1080px;
    margin: 20px;
    padding-left: 10px;
    overflow: hidden;
    float: left;
}

.cate-img {
  margin: 0px 10px 10px 10px;
  width: 80px;
  height: 85px;
  overflow: hidden;
}

.cate-img img {
  width: 100%;
  height: 100%;
}
.cate-item {
  display: flex;
}

.global-nav-item a:hover {
  color: var(--el-color-primary);
  cursor: pointer;
}

.icon-left {
  margin-left: 50px;
}

.line-ellipsis {
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏溢出部分 */
  text-overflow: ellipsis; /* 超出部分以省略号表示 */
  width: 120px;
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-wrap: break-word; */
}

.all-sort {
  width: 130px;
  padding-left: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-wrap: break-word;
}

.sort-title {
  font-size: 14px;
  font-family: Source Han Sans;
  font-weight: bold;
}
</style>

<style>
.xg-menu-popover{
  padding: 0 !important;
  border: none !important;
  margin-top: 0 !important;
  border-radius: unset !important;
  max-height: 800px; /* 设置最大高度 */
  overflow-y: auto; /* 垂直滚动条 */
}

.popover-menu {
  padding-bottom: 10px !important;
}
</style>
