/**
 * Created by PanJiaChen on 16/11/18.
 */
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
export function debounce(func, wait, immediate) {
  let timer
  return function () {
    const context = this
    const args = arguments

    if (timer) clearTimeout(timer)
    if (immediate) {
      const callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timer = setTimeout(() => {
        func.apply(context, args)
      }, wait)
    }
  }
}

export function isArrayFn(o) {
  return Object.prototype.toString.call(o) === '[object Array]'
}

export function onSlideClickTo(item) {
  const { url } = item
  if (url) {
    window.location.href = url
  }
}

import router from '../router'

export function articleTo(item) {
  const { source_link, target, alias, id } = item
  if (source_link) {
    if (target) {
      window.open(source_link)
    } else {
      window.location.href = source_link
    }
  } else if (id) {
    router.push({
      name: 'article-detail',
      query: {
        id
      }
    })
  }
}

export function activityTo(item) {
  const { source_link, target, alias } = item
  if (source_link) {
    if (target) {
      window.open(source_link)
    } else {
      window.location.href = source_link
    }
  } else {
    router.push({
      name: 'activity',
      query: {
        alias
      }
    })
  }
}

export function delHtmlTag(str) {
  if (str) {
    return str.replace(/<[^>]+>/g, '') // 去掉所有的html标记
  } else {
    return ''
  }
}

export function slideTo(item) {
  const { type_id, url, jump_type_id, type_item_alias, type_item_id } = item
  if (type_id == 2 && url) {
    window.open(url)
  } else if (type_id == 1) {
    switch (jump_type_id) {
      case 1:
        if (type_item_alias) {
          router.push({
            name: 'sort',
            query: {
              alias: type_item_alias,
              id: type_item_id
            }
          })
        }
        break
      case 4:
        if (type_item_id) {
          router.push({
            name: 'article-detail',
            query: {
              id: type_item_id
            }
          })
        }
        break
      case 5:
        if (type_item_id) {
          router.push({
            name: 'GoodsDetail',
            query: {
              id: type_item_id
            }
          })
        }
        break

      default:
        break
    }
  }
}
