<template>
  <div class="list">
    <div v-for="(item, key) in datas" :key="key" class="item" :class="{ 'item-active': key == current }">
      <a href="javascript:;" class="item-href" @click="onItem(key, item)">
        {{ item.title }}
      </a>
    </div>
    <!-- <div class="item" :class="{ 'item-active': 'national' == current }" @click="onItem('national', { name: 'national' })">
      <a href="javascript:;" class="item-href">
        {{ $t('global.countryPavilions') }}
      </a>
    </div> -->
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

// import emitter from '@/utils/mitt'
const store = useStore()
const route = useRoute()
const router = useRouter()
const current = ref(route.name)
const onItem = (idx, item) => {
  current.value = idx
  // console.log('idx', idx)
  if (idx == 'travel') {
    // 企业差旅出行
    window.open('https://b2b.kingbarin.com/#/travel')
    return
  } else if (idx == 'purchase') {
    // 企业集采入口
    window.open('https://uat.xun-b2b.kingbarin.com')
    return
  } else if (idx == 'welfare') {
    // 员工福利
    window.open('https://welfare.kingbarin.com.cn')
    return
  } else if (idx == 'home') {
    router.push({
      path: '/'
    })
    return
  }

  if (item && item.name) {
    router.push({
      'name': item.name
    })
    // const { name } = item
    // if (name) {
    //   router.push({
    //     name
    //   })
    // }
  }
}
const datas = computed(() => {
  return store.getters?.RouterNavigation
})
watch(
  route,
  (e) => {
    current.value = e.name
  },
  { immediate: true }
)
</script>
<style lang="scss" scoped>
.list {
  display: flex;
  align-items: center;
  padding-top: 5px;
  // justify-content: flex-end;

  &-line {
    width: 274px;
    height: 1px;
    background: #231815;
  }
}

.item {
  position: relative;
  margin-right: 30px;
  height: 50px;
  cursor: pointer;
  min-width: 80px;

  &-href {
    font-size: 17px;
    font-weight: 600;
    color: #303133;
    line-height: 25px;
    transition: all 0.3s ease;
    text-shadow: 0 0 1px #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /*no*/
  }

  &-after {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    bottom: 15px;
  }

  &-line {
    width: 100%;
    height: 4px;
    background: var(--el-color-primary);
    opacity: 0;
    position: absolute;
    transform: translate(100%, 0);
    transition: all 0.5s ease;
    top: 5px;
  }

  &:hover,
  &.item-active {

    // text-decoration:underline;
    // color: var(--el-color-primary);
    .item {
      // overflow: hidden;

      &-href {
        color: var(--el-color-primary);
      }

      // &-line {
      //   opacity: 1;
      //   left: 50%;
      //   transform: translate(-50%, 0);
      //   transition: all 0.5s ease;
      // }
    }
  }
}

// .w-about {
//   width: 140px;
// }

// .line-ellipsis {
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 2;
//   overflow: hidden;
//   word-wrap: break-word;
//   text-overflow: ellipsis;
// }</style>
