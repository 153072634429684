import { accountInfo, logout } from '@/api/app.js'
import { cartCreate, cartList } from '@/api/cart.js'
import { getToken, removeToken } from '@/utils/auth'
import { ElNotification } from 'element-plus'
import { t } from '@/lang'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    info: {},
    signature: 0,
    cartData: {},
    // 路由导航数据
    RouterNavigation: {
      home: {
        title: t('global.home'),
        name: 'home'
      },
      // about: {
      //   title: t('global.aboutUs'),
      //   name: 'about'
      // },
      // solution: {
      //   title: '解决方案',
      //   name: 'solution'
      // },
      // welfare: {
      //   title: t('global.enterpriseBenefits'),
      //   name: 'welfare'
      // },
      // travel: {
      //   title: t('global.travel')
      // },
      purchase: {
        title: t('global.enterpriseProcurement')
      }
      // programme: {
      //   title: '行业解决方案',
      //   name: 'programme'
      // }
    }
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ACCOUNT_INFO: (state, info) => {
    state.info = info
  },
  SET_BANNER: (state, banners) => {
    state.banners = banners
  },
  SET_CART: (state, objects) => {
    state.cartData = objects
  }
}

const actions = {
  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      commit('SET_CART', {})
      resolve()
      setTimeout(() => {
        location.reload()
      }, 2000)
    })
  },
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      accountInfo({})
        .then((res) => {
          resolve(res)
          const { data } = res
          commit('SET_ACCOUNT_INFO', data)
        })
        .catch(() => {
          reject()
        })
    })
  },
  getLogout({ dispatch }) {
    return new Promise((resolve, reject) => {
      logout({})
        .then((res) => {
          resolve(res)
          dispatch('resetToken')
        })
        .catch(() => {
          reject()
        })
    })
  },
  getCartList({ commit }) {
    return new Promise((resolve, reject) => {
      cartList({})
        .then((res) => {
          resolve(res)
          const { error, data } = res
          if (error === 0) {
            commit('SET_CART', data)
          }
        })
        .catch(() => {
          reject()
        })
    })
  },
  addCart({ dispatch }, state) {
    return new Promise((resolve, reject) => {
      const { goods_alias, goods_number } = state || {}
      cartCreate({
        goods_alias,
        goods_number
      })
        .then((res) => {
          resolve(res)
          const { error, msg } = res
          if (error == 0) {
            ElNotification({
              title: msg || 'success',
              type: 'success'
            })
            dispatch('getCartList')
          }
        })
        .catch(() => {
          reject()
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
