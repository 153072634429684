import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
// 引入全局样式文件
import 'animate.css/animate.min.css'
import 'normalize.css'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import '@/style/index.css'
import '@/style/style.css'
// 引入全局路由守卫
import '@/permission.js'

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { activityTo, articleTo, curriculumTo } from '@/utils/index.js'

import App from './App.vue'
// elementPlus
import ElementPlus from 'element-plus'
import LayoutFooter from './layout/Footer.vue'
import { createApp } from 'vue'
import router from './router'
import store from './store'
import i18n from './lang'
import VueTelInput from 'vue3-tel-input'
import './assets/css/vue-tel-input.css'

const myApp = createApp(App)
// 注册所有element-plus的图标
// for (const [key, component] of Object.entries(ElIcons)) {
//   myApp.component(key, component)
// }
myApp.component('layout-footer', LayoutFooter)
myApp.component(Swiper.name, Swiper)
myApp.component(SwiperSlide.name, SwiperSlide)
// myApp.component('Vue3TelInput', Vue3TelInput)
myApp.config.globalProperties.$articleTo = articleTo
myApp.config.globalProperties.$activityTo = activityTo
myApp.config.globalProperties.$curriculumTo = curriculumTo
myApp.use(store).use(router).use(ElementPlus).use(i18n).use(VueTelInput).mount('#app')
