import { createRouter, createWebHashHistory } from 'vue-router'
import { routes } from './list.js'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
