// import defaultSettings from '@/settings'
import { t } from '@/lang'

const title = t('global.kingBarin')

export default function getPageTitle(pageTitle) {
  if (pageTitle && pageTitle != title) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}
