import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/account/login',
    method: 'post',
    data
  })
}

export function logout(data) {
  return request({
    url: '/account/logout',
    method: 'post',
    data
  })
}
/**
 *
 * 重置密码
 * @param {]} data
 * @returns
 */
export function accountResetPassword(data) {
  return request({
    url: '/account/reset-password',
    method: 'post',
    data
  })
}

/**
 *
 * 验证码
 * @param {]} data
 * @returns
 */
export function smsSend(data) {
  return request({
    url: '/sms/send',
    method: 'post',
    data
  })
}

export function accountInfo(params) {
  return request({
    url: '/account/info',
    method: 'get',
    params
  })
}

export function accountUpdateAvatar(data) {
  return request({
    url: '/account/update-avatar',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

export function commonUploadImg(data) {
  return request({
    url: '/common/upload-img',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

/** 流程 */

export function checkinProcessList(params) {
  return request({
    url: '/checkin-process/list',
    method: 'get',
    params
  })
}

/** 区域列表 */
export function areaList(params) {
  return request({
    url: '/area/list',
    method: 'get',
    params
  })
}

/** 三级 省、市、区联动 */
export function areaCascadeList(params) {
  return request({
    url: '/area/cascade-list',
    method: 'get',
    params
  })
}

/** 全国区列表 */
export function areaDistrictList(params) {
  return request({
    url: '/area/district-list',
    method: 'get',
    params
  })
}

/** /common/list */
export function commonSchoolList(params) {
  return request({
    url: '/common/school-list',
    method: 'get',
    params
  })
}

/** 文章 */
export function articleList(params) {
  return request({
    url: '/article/list',
    method: 'get',
    params
  })
}

export function articleView(params) {
  return request({
    url: '/article/view',
    method: 'get',
    params
  })
}

export function slideList(params) {
  return request({
    url: '/slide/list',
    method: 'get',
    params
  })
}

export function activityList(params) {
  return request({
    url: '/activity/list',
    method: 'get',
    params
  })
}

export function activityView(params) {
  return request({
    url: '/activity/view',
    method: 'get',
    params
  })
}

export function articleSortList(params) {
  return request({
    url: '/article-sort/list',
    method: 'get',
    params
  })
}

export function facultyList(params) {
  return request({
    url: '/faculty/list',
    method: 'get',
    params
  })
}

export function partnerList(params) {
  return request({
    url: '/partner/list',
    method: 'get',
    params
  })
}

export function brandList(params) {
  return request({
    url: '/brand/list',
    method: 'get',
    params
  })
}

export function activityTypeList(params) {
  return request({
    url: '/activity/type-list',
    method: 'get',
    params
  })
}

export function consultCreate(data) {
  return request({
    url: '/consult/create',
    method: 'post',
    data
  })
}

export function goodsSortScenesSortList(params) {
  return request({
    url: '/goods-sort/scenes-sort-list',
    method: 'get',
    params
  })
}

export function countryPavilions(params) {
  return request({
    url: '/national/country-pavilions',
    method: 'get',
    params
  })
}

export function countryPavilionsSpecial(params) {
  return request({
    url: '/national/country-pavilions-special',
    method: 'get',
    params
  })
}
