import request from '@/utils/request'

/** timeline */
export function cartList(params) {
  return request({
    url: '/cart/list',
    method: 'get',
    params
  })
}

/** /cart/create */
export function cartCreate(data) {
  return request({
    url: '/cart/create',
    method: 'post',
    data
  })
}

/** /cart/create */
export function cartUpdate(data) {
  return request({
    url: '/cart/update',
    method: 'post',
    data
  })
}

export function cartDelete(data) {
  return request({
    url: '/cart/delete',
    method: 'post',
    data
  })
}

/** /cart/truncate */
export function cartTruncate(data) {
  return request({
    url: '/cart/truncate',
    method: 'post',
    data
  })
}
/** /cart/selected */
export function cartSelected(data) {
  return request({
    url: '/cart/selected',
    method: 'post',
    data
  })
}

/** 下单结算 */
export function cartCheckout(data) {
  return request({
    url: '/cart/checkout',
    method: 'post',
    data
  })
}
