<template>
  <header class="header">
    <div ref="otherRef">
      <div class="header-bd">
        <div class="header-bd__hd">
            <a class="header-logo" href="/">
              <img src="../assets/logo.png" alt="" />
            </a>
          </div>
        <div class="header-top">
          <slot><categorize/></slot>
          <RouterList />
          <div class="header-usercenter">
            <slot>
              <Search />
              <el-divider direction="vertical"/>
              <User />
              <el-divider direction="vertical"/>
              <el-badge :value="cartNumber" :hidden="cartNumber <= 0" :max="99" class="item"
                @click="router.push({ name: 'cart' })">
                <div class="header-bd__cart"></div>
                <div class="cart-title">{{ $t('global.cart') }}</div>
              </el-badge>
              <!-- <el-divider direction="vertical"/>
              <commonLinks/>
              <el-divider direction="vertical"/> -->
            </slot>
            <!-- <Language /> -->
          </div>
        </div>
      
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, defineExpose, computed, watch } from 'vue'
import RouterList from './components/router-list.vue'
import Search from './components/search.vue'
import User from './components/user.vue'
import Language from './components/language.vue'
import commonLinks from './components/common-links.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Categorize from './components/categorize.vue'

const route = useRoute()
const otherRef = ref(null)

const headerHeight = ref('')
const current = ref(route.name)
const router = useRouter()
const store = useStore()
onMounted(() => {
  headerHeight.value = otherRef.value?.offsetHeight
})

watch(
  route,
  (e) => {
    current.value = e.name
  },
  { immediate: true }
)

defineExpose({ headerHeight })

const cartNumber = computed(() => {
  return store.getters?.cartData?.goods_count || 0
})
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  &-topmost {
    position: relative;
    width: 100%;
    height: 90px;
    overflow: hidden;

    &-bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: 360px auto;
      background-repeat: repeat;
      // background-position: center;
      filter: blur(10px);
      transform: scale(10);
    }

    .container {
      position: absolute;
      width: 1400px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .el-icon {
        position: absolute;
        top: 2px;
        right: 24px;
        font-size: 28px;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  &-hd {
    height: 50px;
    font-size: 16px;
    color: #ffffff;
    background-color: #000;
    line-height: 50px;
    text-align: right;

    .el-dropdown {
      height: 50px;
      line-height: 50px;

      &-link {
        color: #fff;
        font-size: 14px;
      }
    }
  }

  &-logo {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 0;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  &-bd {
    font-family: title-font;
    background-color: #fff;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.3);

    &-container {
      width: 1400px;
      margin: 0 auto;
    }

    &__hd {
      width: 160px;
      height: 56px;
      position: absolute;
      left: 5%;
      top: 10px;
    }

    &__bd {
      display: flex;
      align-items: center;
    }

    &__cart {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: url('../assets/icon-cart.png') no-repeat center;
      background-size: 30px 30px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter {
  opacity: 0;
  height: 0px;
}

.fade-enter-to {
  opacity: 1;
  height: 90px;
}

.cart {
  &-title {
    white-space: nowrap;
    margin-left: 5px;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    color: #606266;
    line-height: 39px;
  }
}

.item.el-badge {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-top {
  margin: 0px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  padding: 0px calc((100% - 1200px)/2);
  height: 70px;
}
.header-usercenter {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  gap: 10px;
}

.header-bd .search{
  margin-left: auto;
}

@media (max-width: 768px) {
  .header-bd__hd {
    left: 0;
  }
}
</style>
