import Layout from '@/layout/index.vue'
import { t } from '@/lang'
export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: { title: t('global.login') }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/',
    children: [
      // {
      //   path: '/',
      //   name: 'site',
      //   component: () => import('@/views/site'),
      //   meta: { title: t('global.home') }
      // },
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home'),
        meta: { title: t('global.home') }
      },
      // {
      //   path: '/about',
      //   name: 'about',
      //   component: () => import('@/views/about'),
      //   meta: { title: t('global.aboutUs') }
      // },
      {
        path: '/hot',
        name: 'hot',
        component: () => import('@/views/hot'),
        meta: { title: t('home.hotProducts') }
      },
      {
        path: '/scenario',
        name: 'scenario',
        component: () => import('@/views/scenario'),
        meta: { title: t('home.senarioPro') }
      },
      {
        path: '/special',
        name: 'special',
        component: () => import('@/views/special'),
        meta: { title: '专题专区' }
      },
      // {
      //   path: '/welfare',
      //   name: 'welfare',
      //   component: () => import('@/views/welfare'),
      //   meta: { title: '企业员工福利' }
      // },
      // {
      //   path: '/welfare/sort',
      //   name: 'welfare-sort',
      //   component: () => import('@/views/sort/welfare.vue'),
      //   meta: { title: '企业员工福利' }
      // },
      // {
      //   path: '/solution',
      //   name: 'programme',
      //   component: () => import('@/views/programme'),
      //   meta: { title: '行业解决方案' }
      // },
      {
        path: '/goodsDetail',
        name: 'GoodsDetail',
        component: () => import('@/views/goodsDetail'),
        meta: { title: t('order.goodsDetail') }
      },
      {
        path: '/result',
        name: 'result',
        component: () => import('@/views/result'),
        meta: { title: '搜索结果' }
      },
      {
        path: '/sort',
        name: 'sort',
        component: () => import('@/views/sort'),
        meta: { title: t('dashboard.productList') }
      },
      {
        path: '/cart',
        name: 'cart',
        component: () => import('@/views/cart'),
        meta: { title: t('global.cart') }
      },
      {
        path: '/article',
        name: 'article',
        component: () => import('@/views/article'),
        meta: { title: '文章' }
      },
      {
        path: '/article/detail',
        name: 'article-detail',
        component: () => import('@/views/article/detail.vue'),
        meta: { title: '文章详情' }
      },
      // {// 国家馆
      //   path: '/national',
      //   name: 'national',
      //   component: () => import('@/views/national/index.vue')
      // },
      // {// 国家馆商品
      //   path: '/national/goods/:country',
      //   name: 'national-goods',
      //   component: () => import('@/views/national/goods-list')
      // },
      // {// 国家馆介绍
      //   path: '/national/introduce/:country',
      //   name: 'national-introduce',
      //   component: () => import('@/views/national/introduce')
      // },
      {
        path: '/mine',
        name: 'mine',
        component: () => import('@/views/mine'),
        meta: { title: t('global.userCenter') },
        redirect: '/mine/info',
        children: [
          {
            path: 'order',
            name: 'order',
            component: () => import('@/views/mine/order'),
            meta: { title: t('dashboard.myOrder') }
          },
          {
            path: 'order/detail',
            name: 'order-detail',
            component: () => import('@/views/mine/order/detail.vue'),
            meta: { title: t('order.orderDetail') },
            hidden: true
          }
        ]
      },
      // {// 解决方案
      //   path: '/solution',
      //   name: 'solution',
      //   component: () => import('@/views/solution/index.vue')
      // },
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]
