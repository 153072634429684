import { getToken, removeCookies, removeToken, setCookies, getCookies } from '@/utils/auth'

import { ElMessage } from 'element-plus'
import axios from 'axios'
import router from '../router/index.js'

// import store from '@/store'

// create an axios instance
const service = axios.create({
  baseURL: api_url,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
  headers: {
    // 'Content-Type': 'application/json'
    'Content-Type': 'application/x-www-form-urlencoded'
    // 'Content-Type': 'multipart/form-data'
  }
})
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers['Access-Token'] = getToken() || ''
    // config.headers['app_alias'] = 'c203f45d3a4a27ea30e2337e2668e274'
    config.params = config.params ? config.params : {}
    config.params['app_alias'] = 'e86a50bd35fe5ff08cde02ecc901bde5'
    config.params['shop_alias'] = '135fa7b5e4afffafec380dcab1db0761'
    config.params['language'] = getCookies('currentLanguage')
    config.data = config.data ? config.data : {}
    config.data['app_alias'] = 'e86a50bd35fe5ff08cde02ecc901bde5'
    config.data['shop_alias'] = '135fa7b5e4afffafec380dcab1db0761'
    config.data['language'] = getCookies('currentLanguage')
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// 用于判断确认弹框是否已经存在避免重复调用
// let flag = false

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (response.config.url == '/account/update-avatar') {
      removeCookies('KINGBARIN_WWW_GOODS_ALIAS')
      removeCookies('KINGBARIN_WWW_GOODS_NUMBER')
      return res
    }
    if (response.config.url == '/common/upload-img') {
      removeCookies('KINGBARIN_WWW_GOODS_ALIAS')
      removeCookies('KINGBARIN_WWW_GOODS_NUMBER')
      return res
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.error !== 0) {
      if (res.error >= 40000) {
        if (response.config.url == '/cart/create') {
          if (response.config.data) {
            const tempData = JSON.parse(response.config.data)
            const { goods_alias, goods_number } = tempData
            setCookies('KINGBARIN_WWW_GOODS_ALIAS', goods_alias)
            setCookies('KINGBARIN_WWW_GOODS_NUMBER', goods_number)
          }
        } else {
          removeCookies('KINGBARIN_WWW_GOODS_ALIAS')
          removeCookies('KINGBARIN_WWW_GOODS_NUMBER')
        }
        // if (flag) return Promise.reject(res)
        // flag = true
        // token过期 重新登录
        removeToken()
        let redirect = '';
        const url = window.location.href; // 获取当前 URL
        // Step 1: 获取哈希部分
        const hashIndex = url.indexOf('#');

        // 检查是否存在哈希部分
        if (hashIndex !== -1) {
          const hashPart = url.substring(hashIndex + 1); // 移除 '#' 前缀
          // Step 2: 提取查询参数
          const queryIndex = hashPart.indexOf('?');
          // 检查是否存在查询字符串
          if (queryIndex !== -1) {
            const queryString = hashPart.substring(queryIndex + 1); // 移除 '?' 前缀
            const urlParams = new URLSearchParams(queryString); 
            // 获取 redirect 参数的值
            redirect = urlParams.get('redirect');
          } 
        }
        
        // flag = false
        // 重定向至登录页面
        router.replace({
          name: 'login',
          query: {
            redirect: redirect ? redirect : window.location.href.split('/#')[1] || '/',
          }
        })
        // flag = false
      } else if (res.error == 1007) {
        return res
      } else {
        ElMessage({
          message: res.msg || 'Error',
          grouping: true,
          type: 'error'
        })
      }
      // return Promise.reject(new Error(res.msg || "Error"));
      return Promise.reject(res)
    } else {
      if (response.config.url == '/cart/create') {
        removeCookies('KINGBARIN_WWW_GOODS_ALIAS')
        removeCookies('KINGBARIN_WWW_GOODS_NUMBER')
      }
      return res
    }
  },
  (error) => {
    ElMessage({
      message: error.message || '网络连接失败，请稍后重试',
      grouping: true,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default service
