import Cookies from 'js-cookie'

const TokenKey = 'KINGBARIN_WWW_ACCESS_TOKEN'

export function getToken() {
  // eslint-disable-next-line no-undef
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  // eslint-disable-next-line no-undef
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  // eslint-disable-next-line no-undef
  return Cookies.remove(TokenKey)
}

export function setCookies(key, val) {
  return Cookies.set(key, val)
}

export function getCookies(key) {
  return Cookies.get(key)
}

export function removeCookies(key) {
  return Cookies.remove(key)
}
