import 'nprogress/nprogress.css' // progress bar style

import { getCookies, getToken } from '@/utils/auth.js'

import NProgress from 'nprogress' // progress bar
import getPageTitle from '@/utils/get-page-title'
import router from './router'
import store from '@/store'
import { t } from '@/lang'

const accountList = ['login']
const blacklist = ['mine', 'order', 'cart']
const whiteList = []
NProgress.configure({
  showSpinner: false
}) // NProgress Configuration
router.beforeEach(async (to, from, next) => {
  //   // set page title
  document.title = getPageTitle(to.meta.title || t('global.kingBarin'))
  const hasToken = getToken()
  if (whiteList.indexOf(to.name) != -1) {
    next()
    return
  } else {
    if (hasToken) {
      const { account_info, cartData } = store.getters
      const { mobile } = account_info || {}
      if (!mobile) {
        await store.dispatch('app/getInfo')
        await store.dispatch('app/getCartList')
      }
      if (getCookies('KINGBARIN_WWW_GOODS_ALIAS')) {
        store.dispatch('app/addCart', {
          goods_alias: getCookies('KINGBARIN_WWW_GOODS_ALIAS'),
          goods_number: getCookies('KINGBARIN_WWW_GOODS_NUMBER')
        })
      }
      // if (blacklist.includes(to.name)) {
      //   const { items } = cartData || {}
      //   if (!items) {
      //     await store.dispatch('app/getInfo')
      //   }
      // }
      next()
      return
    } else {
      // store.commit('app/SET_CART', {})
      // store.commit('app/SET_ACCOUNT_INFO', {})
      if (blacklist.includes(to.name)) {
        next(`/login?redirect=${to.path}`)
      } else {
        next()
        return
      }
    }
    return
  }
})

router.afterEach(() => {
  document.body.scrollTop = document.documentElement.scrollTop = 0
  // finish progress bar
  NProgress.done()
})
