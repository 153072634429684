export default {
  global: {
    kingBarin: 'King Barin',
    home: 'Home',
    login: 'Sign in',
    switchLanguage: 'Switch the language',
    langZh: 'Chinese',
    langEn: 'English',
    cart: 'Cart',
    userCenter: 'My account',
    welcome: 'Welcome to GITA BOX!',
    searchContent: 'Please enter what you want to search',
    search: 'Search',
    contactUs: 'Contact Us',
    success: 'Success',
    fail: 'Failure',
    logout: 'Sign out',
    sendFail: 'Sending failure, please try again later!',
    clickCheck: 'Click to view',
    checkMore: 'View more',
    operate: 'Operate',
    delete: 'Delete',
    deleteSuccess: 'Delete successfully',
    cancel: 'Cancel',
    save: 'Save',
    cancelSuccess: 'Cancel successfully',
    editSuccess: 'Edit successfully',
    submit: 'Submit',
    none: 'None',
    confirm: 'Confirm',
    uploadFail: 'Upload failed. Please try again.',
    uploadSuccess: 'Upload successful',
    clear: 'Clear',
    saveSuccess: 'Save successfully',
    operation_manual: 'Operation Manual',
    dashboard: 'Dashboard',
    notice: 'Information',
    noticeDes: 'You have new news, please check it in time.',
    shopInfo: 'Enterprise Information',
    supplier: 'Suppliers',
    oaApproval: 'OA Approval',
    purchaseOrder: 'Purchase Orders',
    productAnalysis: 'Analysis',
    help: 'Help',
    aboutUs: 'About Us',
    enterpriseBenefits: 'Employee Benefit',
    travel: 'Business Trip',
    enterpriseProcurement: 'Enterprise Centralized Procurement',
    webTitle: 'Professional Scene Intelligent Integrated Supply Chain Service Provider',
    webDes: 'Provide customers with full-chain supply chain solutions',
    address: '4501-01A, Building A, Kingkey 100, Caiwuwei, No. 5016, Shennan East Road, Laowei Community, Luohu District, Shenzhen',
    customerService: 'Online Service',
    countryPavilions: 'National Pavilions',
    enter: 'Enter',
    more: 'More',
    solution: 'Solution',
    categories: 'Categories',
    commonLinks: 'External links',
    rate: 'Foreign exchange rate',
  },
  home: {
    senarioPro: 'Senario Procurement Zone',
    cooperativeBrands: 'Cooperative Brands',
    hotProducts: 'Hot Selling Products',
    partner: 'Partners',
    commodityZone: 'Commodity Zone',
    cooperation: 'Communication on cooperation',
    demandResearch: 'Demand research',
    signing: 'Signing & Cooperation',
    solution: 'Solution',
    deployment: 'Deployment and implementation',
    optimization: 'Review and optimize'
  },
  login: {
    passwordLogin: 'Password Sign in',
    mobileLogin: 'Mobile number Sign in',
    phoneName: 'Mobile number or Username',
    inputName: 'Please enter your mobile number or user name',
    inputPossword: 'Please enter your password',
    selectArea: 'Please select an area code',
    inputMobile: 'Please enter your mobile number',
    inputCaptcha: 'Please enter the verification code',
    getCaptcha: 'Send verification code',
    registerAccount: 'Sign up',
    forgetPassword: 'Forgot your password',
    loginSuccess: 'Login successfully, welcome',
    residue: 'Residual',
    loggingIn: 'Logging in...',
    checkInfo: 'Please check whether the information is complete',
    loginDesc: 'It is detected that you are not logged in, please log in first.',
    loginExpired: 'Login status has expired, please log in again.',
    captcha: 'Verification code',
    pleaseSelectEmail: 'Please enter your Email',
    password: 'Password',
    signUpSuccess: 'Registration successful. Please log in.',
    logouting: 'Signing out...',
    accountLoginTips: 'Tips: If use phone number to log in, space needs to be added between the area code and the phone number, such as:1 30*******'
  },
  dashboard: {
    userProfile: 'Account',
    orderCenter: 'Orders',
    addressManagement: 'Addresses',
    accountInfo: 'Profile',
    myCollect: 'My collection',
    changePassword: 'Password reset',
    myOrder: 'My order',
    refund: 'Refund/Aftersales',
    subNotice: 'Subscribe Notification',
    realName: 'Real Name Information',
    statement: 'Statement',
    supplierList: 'Supplier List',
    productList: 'Product List',
    changeRecord: 'Change Record',
    activityRecords: 'Supplier Activity Records',
    approvalProcess: 'Approval Process',
    personnel: 'Personnel',
    approvalForm: 'Purchase Approval Form'
  },
  goods: {
    addCart: 'Add to Cart',
    collect: 'Collect',
    cancleCollect: 'Cancel collection',
    collectSuccess: 'Add to collection successfully',
    cancelCollectSuccess: 'Remove from collection successfully',
    productIntroduction: 'Product Introduction',
    productParameters: 'Product Specifications',
    buyNow: 'Buy Now',
    price: 'Price',
    quantity: 'Quantity',
    boxGgauge: 'Carton size',
    delivery: 'Delivery',
    deliveryDesc: 'Actual arrival date is subject to the logistics service provider. ',
    minBuyNum: 'The minimum order quantity cannot be less than 1',
    collectTime: 'Collection time',
    cancelCollectNotice: 'Are you sure to cancel the collection?',
    sales: 'Sales',
    latest: 'Latest',
    totalNum: 'Total {number}',
    pcs: 'PCS',
    bag: 'bag'
  },
  user: {
    avatar: 'Avatar',
    password: 'Password',
    confirmPassword: 'Confirm password',
    name: 'Name',
    nickname: 'Nickname',
    email: 'Email',
    sex: 'Gender',
    male: 'Male',
    female: 'Female',
    age: 'Age',
    birthday: 'Birthday',
    description: 'Description',
    account: 'Account',
    mobile: 'Mobile number',
    pleaseSelectName: 'Please enter your username',
    pleaseSelectMobile: 'Please enter your mobile number',
    passwordAgin: 'Please re-enter your password',
    setPassword: 'Please enter your password',
    passwordCondition: 'The password should contain 8 to 20 characters and include both letters and digits.',
    noSamePassword: 'The password you entered latter is not coinsistent with the former.',
    userExists: 'Userame already exists.',
    usePasswordEdit: 'Modify by password',
    oldPassword: 'Original password',
    inputOldPassword: 'Please enter the original password',
    newPassword: 'New password',
    inputNewPassword: 'Please enter a new password',
    inputNewPasswordAgain: 'Please enter the new password again',
    userMobileEdit: 'Modify by phone number',
    birthdayOne: 'Birthday can only be set once, please fill it in carefully. If modifications are needed, please contact customer service for manual handling.'
  },
  cart: {
    allSelect: 'Select all',
    goodsInfo: 'Goods information',
    price: 'Unit price',
    amount: 'Amount',
    invalidProduct: 'Invalid goods',
    clearInvalidProduct: 'delete invalid goods',
    deleteSelect: 'Delete selected items',
    selectGoodsNum: 'Selected {number} pieces',
    total: 'Total',
    toPay: 'To pay',
    pleaseSelectGoods: 'Please select goods',
    confirmDelete: 'Confirm to delete the item?',
    currencySame: 'Only goods of the same currency can be selected',
    pleaseAddCart: "The shopping cart's empty. Let's pick out something good",
    goShopping: 'Go shopping'
  },
  order: {
    comfirmOrder: 'Confirm the order',
    enterOrder: 'Fill in and check the order information',
    address: 'Delivery address',
    expand: 'Unfold',
    retract: 'Fold',
    moreAddress: 'More delivery addresses',
    payType: 'Payment method',
    onlinePay: 'Pay online',
    goodsList: 'List of goods',
    message: 'Message',
    description: "If there's any special delivery requirement, please note it in the message",
    totalAmount: 'Total value',
    shippingFee: 'Freight',
    shippingFeeDesc: 'Freight note',
    shippingFeeContent: {
      payment: 'The freight fee will be updated within 48hrs, you will able to settle the payment only upon total charge is updated. Your action of payment is the acknowledgement and acceptance in confirming the total payment.',
      conformation: 'The freight fee will be updated within 48hrs, you will able to settle the payment only upon total charge is updated.'
    },
    payTotal: 'Total amount payable',
    submitOrder: 'Checkout',
    totalOrders: 'Total {number} pieces of goods',
    selectAddress: 'Please select the address first',
    submitSuccess: 'Order successful',
    orderNum: 'Order ID',
    currentStatus: 'Current order status',
    shippingFeeCalculate: 'Calculation the freight',
    paySuccess: 'Pay successfully',
    shopShipment: 'Merchant delivery',
    comfirmReceive: 'Confirm receipt',
    transactionComplete: 'Transaction completed',
    shippingFeeNotice: '<h2>Dear customer</h2><p>Your shipping fee will be calculated within <span>48 hours</span> and will be sent to the mobile number you provided during registration <span>via SMS</span>. Please return to this order for <span>payment</span> once you receive the message.</p>',
    payment: 'Payment',
    cancelOrder: 'Cancel order',
    subtotal: 'Subtotal',
    applyRefund: 'Ask for aftersales service',
    viewRefund: 'Check aftersales sevice',
    logisticDetail: 'Details of Logistics',
    sendTime: 'Delivery time',
    logisticCompany: 'Logistics company',
    logisticNum: 'Tracking number',
    logisticNone: 'No logistics information available at the moment',
    logisticTacking: 'Logistics tracking',
    goodsDetail: 'Details of goods',
    status: 'Status',
    consigneeInfo: 'Consignee information',
    consignee: 'consignee',
    mobile: 'Mobile number',
    remark: 'Remarks',
    ok: 'OK',
    confirmCancelOrder: 'Are you sure to cancel the order?',
    awaitPay: 'Order submitted successfully, please pay as soon as possible! Order ID {number}',
    totalAmount: 'Total amount',
    paySelect: 'Select a payment method',
    payAmount: 'Payment amount',
    paySuccessed: "Congratulations!You've paid successfully",
    viewOrder: 'View the order',
    returnHome: 'Return to homepage',
    allOrder: 'All orders',
    awaitShippingFee: 'Shipping fee to be calculated',
    awaitToPay: 'To be paid',
    awaitSend: 'To be shipped',
    awaitReceive: 'To be received',
    completed: 'Completed',
    searchGoods: 'Please enter goods name or order number',
    confirmDeleteOrder: 'Are you sure to delete the order?',
    confirmReceiveOrder: 'Are you sure to confirm receipt?',
    receiveSuccess: 'Sign and receive successfully',
    orderDetail: 'Order details',
    specs: 'Specification',
    enterLogisticNum: 'Fill in the logistics tracking number',
    repurchase: 'Purchase again',
    expressCompany: 'Courier company',
    expressNum: 'Courier number',
    selectExpressCom: 'Please select logistics company!',
    selectExpressNum: 'Please fill in the logistics tracking number!',
    operateSuccess: 'Operate successfully',
    search: 'Search',
    paypalPay: 'PayPal Pay',
    wechatPay: 'WeChat Pay',
    wechatPayTip1: 'Please use the WeChat Scan',
    wechatPayTip2: 'Scan QR code for payment',
    productName: 'Product name',
    productType: 'Product type',
    productPicture: 'Product picture',
    unit: 'Unit',
    quantity: 'Quantity',
    volume: 'Volume',
    weight: 'Weight',
    additionalServices: 'Additional services',
    deliveryUpstairs: 'delivery upstairs, repackaging, labeling, etc',
    pleaseProductName: 'Please enter the product name',
    pleaseProductType: 'Please enter the product type',
    pleaseQuantity: 'Please enter the quantity',
    pleaseUnit: 'Please enter the unit',
    pleaseVolume: 'Please enter the volume',
    pleaseWeight: 'Please enter the weight',
    imageLimit: 'Only in jpg/png format, less than 500kb size per image and 5 images to upload at most.',
    addMore: 'Add more',
    attachment: 'Attachment',
    preview: 'preview',
    download: 'download',
    dragFile: 'Drag and drop files here, or click to upload',
    fileLimit: 'Only in jpg / png / word / excel / pdf format, less than {file_max_size}kb size per file and 3 files to upload at most.',
    merchantConsignee: 'Merchant recipient name',
    merchantMobile: 'Merchant recipient phone number',
    merchantAddress: 'Merchant shipping address',
    expressInfo: 'Fill in the tracking number',
    changeExpressInfo: 'Modify the tracking number'
  },
  address: {
    default: 'Default',
    setDefault: 'Set it as default address',
    edit: 'Edit',
    addAddress: 'Add new address',
    firsName: 'First name',
    lastName: 'Last name',
    mobile: 'Mobile number',
    country: 'Country or Region',
    division: 'State/district/province/city',
    address: 'Address',
    postalCode: 'Postcode',
    email: 'Email'
  },
  refund: {
    refundType: 'Refund type',
    refundReason: 'Refund reason',
    refundAmount: 'Refund amount',
    refundExplanation: 'Explanation of refund',
    refundInstructions: 'Please enter your refund instructions',
    submitApply: 'Submit application',
    selectRefundType: 'Please enter your refund type',
    selectRefundReason: 'Please enter your refund reason',
    saleProgress: 'After-sales progress',
    saleInfo: 'After-sales information',
    refundNo: 'Refund ID',
    saleStatus: 'Refund status',
    applyReason: 'Application reason',
    questionDesc: 'Problem description',
    refundLogisticDetail: 'Return shipping information',
    merchantReview: 'Merchant review',
    merchantRefund: 'Merchant Refund',
    refundComplete: 'Refund completed',
    refundGoods: 'Return goods',
    merchantReceive: 'Goods received by Seller',
    underReview: 'Under review',
    reviewSuccess: 'Review failed',
    refundReview: 'To be returned',
    waitResive: 'Wait for Seller to receive the goods',
    waitRefund: 'To be refunded',
    canceled: 'Canceled',
    refundComplete: 'Refund completed',
    refundOnly: 'Refund only',
    returnGoods: 'Return goods for refund',
    repeatOrder: 'Repeat order, no need',
    wrongOrder: 'Wrong purchase and need to repurchase',
    cheaper: 'There are other cheaper channels available',
    qualityProblem: 'Quality problem',
    orderReason: 'Other reasons'
  },
  about: {
    aboutContent: {
      title1: 'Policy Guidance',
      content1: 'Efforts are made to boost the digital transformation of state-owned enterprises according to the spirit of important instructions given by General Secretary Xi Jinping on promoting the  integrated development of digital economy and real economy, in order to fulfill the decisions and arrangements of the CPC Central Committee and the State Council on supporting the further integration of new generation of information technologies and manufacturing sector and  fostering new strengths of digital economy, encourage the development of state-owned enterprises towards digital, networked and intelligent orientation, enhance their competitiveness, innovation capability, control capability, influence and anti-risk capability, and improve basic industrial capacities and the modernization of industry chains.',
      title2: 'King Barin Industry',
      content2: 'is a supply chain service provider with IoTd innovative thinking, specializing in the operation of real estate scenes and providing integrated supply chain services with smart integration for clients. King Barin Industry has always regarded“change + innovation” as the primary driving force for the long-term development of the company and has always stayed at the forefront of the IoT igitalization trend, closely following the  trends of consumer upgrading and industrial upgrading, helping businesses transform from traditional operation models to intelligent integrated supply chain services.',
      content3: 'We aim to rovide customers with solutions to digital intelligent integrated supply chain scenarios and operations, helping businesses realize digital intelligent supply chain transformation, to optimize operational efficiency and enhance competitiveness.'
    },
    service: 'Service Scenarios',
    qual: 'Qualifications',
    report: 'Industry Reporting',
    policy: 'Policy Guidelines',
    coreValue: 'Core Value',
    supplier: 'Supply Chain Management',
    contactUs: 'Contact Us',
    platformAdv: 'Platform Advantages',
    kingbarinPublic: 'King Barin public account',
    gitboxMini: 'Gitabox mini program',
    platform: 'Procurement Platform Deployment Customization',
    purchasingCategories: 'Customization Of Purchasing Categories',
    configService: 'Back-end Warehouse Configuration Service Customization',
    newRetail: 'Customization Of Online And Offline New Retail'
  },
  national: {
    'CNCountry': 'China Pavilion',
    'CNBuy': 'Direct purchase from China',
    'RUCountry': 'Russia Pavilion',
    'RUBuy': 'Direct purchase from Russia',
    'KPCountry': 'North Korea Pavilion',
    'KPBuy': 'Direct purchase from North Korea',
    'AFCountry': 'Africa Pavilion',
    'AFBuy': 'Direct purchase from Africa',
    'BYCountry': 'Belarus Pavilion',
    'BYBuy': 'Direct purchase from Byelorussian',
    'NPCountry': 'Nepal Pavilion',
    'NPBuy': 'Direct purchase from Nepal',
    'THCountry': 'Thailand Pavilion',
    'THBuy': 'Direct purchase from Thailand',
    'MYCountry': 'Malaysia Pavilion',
    'MYBuy': 'Direct purchase from Malaysia',
    'importGoods': 'Imported Goods',
    'importGoodsMore': 'A wide variety of imported goods',
    'buyNow': 'Buy now',
    'briefIntroduce': 'Brief Introduction',
    'advantage': 'Product Features',
    'introduce': 'Introduction',
    'nationalFlag': 'National Flag',
    'nationalEmblem': 'National Emblem',
    'certificate': 'Authorization Letter',
  },
  solution : {
    headCompany: 'Top notch consulting company',
    headEnterprise: 'Top notch entity enterprise',
    supplyManage: 'Supply management',
    supplyTitle: 'Challenges faced by enterprise supply chain management',
    supplyChallenge: '<li>Quantity: Demand fluctuates greatly and is difficult to predict.</li><li>Model: Personalization is required and strong customization R&D capabilities are required.</li><li>Cycle time: Delivery cycles are getting shorter and shorter, and companies need to respond quickly.</li><li>Quality: Consumers are increasingly demanding quality.</li><li>Cost: Market price competition is fierce, and companies are under great pressure to reduce costs.</li>',
    supplyApplication: '<li>Integrated Supply Chain Planning ISCP: Intelligent forecasting; quick response, reduced inventory.</li><li>R&D management PLM: centralized management of customer needs; rapid time to market; full life cycle tracking.</li><li>Quality management QMS: full life cycle traceability; improve quality prevention capabilities.</li><li>Customer collaborative management CRM: order priority management; order commitment management, etc.</li>',
    trainingDes: 'Customize training materials based on the needs of digital projects. Through a combination of theory and practice, we guide each business line of the company to analyze the root causes of business pain points and work with members of the company to find implementable solutions, and promote the company to implement the solutions. , the implementation capability of digital projects has increased by 30%.',
    supplyPlan: 'Supply chain planning',
    performance: 'Performance management',
    workshopDes1: "Set up a co-creation workshop of about 10-20 people based on the company's specific business goals. The host of the workshop will use design thinking methods to inspire each business line of the company to come up with breakthrough ideas, break the traditional way of problem-solving, and help companies find innovative solutions.",
    workshopDes2: '5 Core Steps of the Workshop: Increase the Success Rate of Digital Projects by 30%',
    worksFind: 'Found problem',
    worksSearch: 'Explore root causes',
    worksIdea: 'Propose ideas', 
    deepenIdeas: 'Deepen ideas', 
    inspect: 'Plan test',
    teamDes1: 'Our team comes from world-renowned consulting firms such as McKinsey, Bain, Boston, Accenture, IBM, KPMG, Deloitte, Ernst & Young, PwC, and top 500 companies such as Emerson, Oracle, Microsoft, Lenovo, Huawei, and Trina Solar. A strong company, all members have more than 15 years of practical experience.',
    teamDes2: 'We have the ability to deliver end-to-end integrated supply chain overall solutions, including: top-level planning and implementation of digital projects such as integrated supply chain planning, digital procurement, smart manufacturing, smart logistics, etc. We have rich industry customization capabilities, using digitalization Technology helps companies reduce costs, increase efficiency, improve quality and reduce carbon emissions.',
    scanCode: 'Scan the QR code to follow get the latest news',
    supplyTitle: 'Holistic solution of integrated supply chain',
    trainingTitle: 'The training covers 12 modules',
    advantage1: '70% of our team comes from leading consulting organizations such as McKinsey, Boston, Bain, PwC, Accenture, IBM, KPMG, etc., and has more than 15 years of top-level design experience in large-scale digital projects, including strategic interpretation and pain point diagnosis, gap analysis, solution design, process sorting, capability identification, path planning, system selection, risk assessment, etc.'
  }
}
